import React from 'react';

import { QuestionnaireType } from '@bloom/codegen/models/QuestionnaireType';
import { QuestionType } from '@bloom/codegen/models/QuestionType';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { emptyArray } from '@bloom/ui/utils/empty-value';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';
import { useFileUploader } from '@bloom/library/components/hooks/useFileUploader';
import { useMoment } from '@bloom/library/components/hooks/useMoment';
import { ArrowIcon } from '@bloom/library/components/Icon/Arrow';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import {
  useAnswer,
  useCurrentQuestion,
  useQuestionnaireSubmit,
  useQuestions,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';
import { AddressQuestion } from '@bloom/library/components/QuoteRequest/views/Questions/Address';
import { AttachmentQuestion } from '@bloom/library/components/QuoteRequest/views/Questions/Attachment';
import { BooleanQuestion } from '@bloom/library/components/QuoteRequest/views/Questions/Boolean';
import { ContentQuestion } from '@bloom/library/components/QuoteRequest/views/Questions/Content';
import { DateQuestion } from '@bloom/library/components/QuoteRequest/views/Questions/Date';
import { MultipleChoiceQuestion } from '@bloom/library/components/QuoteRequest/views/Questions/MultipleChoice';
import { OneLineQuestion } from '@bloom/library/components/QuoteRequest/views/Questions/OneLine';
import { PersonalInfoQuestion } from '@bloom/library/components/QuoteRequest/views/Questions/PersonalInfo';
import { TextualQuestion } from '@bloom/library/components/QuoteRequest/views/Questions/Textual';
import { TResponse } from '@bloom/library/types/general';
import { IQuestion } from '@bloom/library/types/questionnaire';
import { ClientInfoFieldEnum } from '@bloom/library/types/templates';
import { convertTimeToObject } from '@bloom/library/utils/date';
import { isEmail } from '@bloom/library/utils/misc';
import { escapeHTML } from '@bloom/library/utils/string';

const errorMessage = 'Please provide an answer';

interface IProps {
  autoFocus: boolean;
  index: number;
  isPreview?: boolean;
  windowObj?: Window;
}

export const QuestionContent: React.FC<{
  autoFocus?: boolean;
  index: number;
  isPreview?: boolean;
  onUploadStatusChange?: (questionIndex: number, isUploading: boolean) => void;
  question: IQuestion;
  windowObj?: Window;
}> = (props) => {
  const { autoFocus, index, isPreview, onUploadStatusChange, question, windowObj } = props;

  // use key property to re-render components if next next question has the same type
  // If we do not do this, the component will reset the input value and bypass it to the next question
  // https://app.clickup.com/t/868581adb
  switch (question.type) {
    case QuestionType.ADDRESS:
      return <AddressQuestion autoFocus={autoFocus} index={index} windowObj={windowObj} />;
    case QuestionType.ATTACHMENT:
      return (
        <AttachmentQuestion
          index={index}
          isPreview={isPreview}
          onUploadStatusChange={onUploadStatusChange}
        />
      );
    case QuestionType.CONTENT:
      return <ContentQuestion question={question} />;
    case QuestionType.TEXTUAL:
      return <TextualQuestion autoFocus={autoFocus} index={index} />;
    case QuestionType.ONE_LINE_TEXT:
      return <OneLineQuestion autoFocus={autoFocus} index={index} />;
    case QuestionType.PERSONAL_INFO:
      return <PersonalInfoQuestion autoFocus={autoFocus} index={index} question={question} />;
    case QuestionType.MULTIPLE_CHOICE:
    case QuestionType.SPECIALTY:
      return <MultipleChoiceQuestion index={index} question={question} />;
    case QuestionType.BOOLEAN:
      return <BooleanQuestion index={index} />;
    case QuestionType.DATE:
      return <DateQuestion question={question} />;
    default:
      return null;
  }
};

const Question: React.FC<IProps> = (props) => {
  const { autoFocus, index, windowObj } = props;

  const [isAnswerSending, { setFalse: stopSendingAnswer, setTrue: startSendingAnswer }] =
    useToggle();

  const { showErrorMessage, showErrorMessageFromResponse } = useInfoMessage();
  const { pending, submitQuestionnaireForm } = useQuestionnaireSubmit();

  const { momentTz } = useMoment();

  const {
    abort,
    processing = emptyArray,
    queue = emptyArray,
  } = useFileUploader({ destination: `questionnaire-form-upload-step-${index}` });

  const [
    { dateTimePicker, isPreview, modal, questionnaire },
    { sendAnswer, setAnswer, showNextQuestion, showPrevQuestion, showView },
  ] = useQuoteRequest();

  const answer = useAnswer(index);

  const question = useCurrentQuestion();
  const questionsList = useQuestions();

  const prevQuestion = questionsList[modal.questionIndex - 1];

  const { post: updateAnswer, status } = useFetch();

  function isValid() {
    // Client info is always required.
    if (question.type !== QuestionType.PERSONAL_INFO && !question.isRequired) {
      return true;
    }

    if (question.type === QuestionType.DATE) {
      if (question.options.allDay && !dateTimePicker.selectedDate) {
        return false;
      }

      if (
        !question.options.allDay &&
        (!dateTimePicker.selectedDate || !dateTimePicker.selectedTime)
      ) {
        return false;
      }

      return true;
    }

    if (question.type === QuestionType.PERSONAL_INFO) {
      const emailIndex = question.options.findIndex((o) => o === ClientInfoFieldEnum.EMAIL_ADDRESS);
      // const phoneIndex = question.options.findIndex(o => o === ClientInfoFieldEnum.PHONE_NUMBER);
      // Not all answers are provided.
      if (answer === undefined || answer.length < question.options.length) {
        return false;
      }
      // At least one answer is empty string
      if (answer.findIndex((a) => !a) > -1) {
        return false;
      }
      // Email Address is invalid
      if (!isEmail(answer[emailIndex])) {
        return false;
      }
    }

    // Moved the statement to the bottom, because SCHEDULING answer sets later from dateTimePicker state
    // in handling next click
    // No answer provided.
    if (answer === undefined || answer === '') {
      return false;
    }

    return true;
  }

  function handleBackClick() {
    abort();

    if (modal.questionIndex === 0) {
      showView(QuoteRequestScreenEnum.INTRO);
    } else {
      showPrevQuestion();
    }
  }

  function handleNextClick() {
    if (isPreview) {
      if (modal.questionIndex === questionsList.length - 1) {
        submitQuestionnaireForm();
      } else {
        showNextQuestion();
      }
      return;
    }

    // check if question is answered
    if (!isValid() && question.type !== QuestionType.CONTENT) {
      showErrorMessage(errorMessage);
      return;
    }

    let payload;

    // 1. Required questions.
    if (answer !== undefined || question.type === QuestionType.DATE) {
      switch (question.type) {
        case QuestionType.PERSONAL_INFO: {
          payload = question.options.reduce(
            (o, name, index) => ({ ...o, [name]: answer[index] }),
            {}
          );
          break;
        }
        case QuestionType.DATE: {
          if (question.options.allDay) {
            payload = dateTimePicker.selectedDate.slice(0, 10);
            setAnswer(modal.questionIndex, dateTimePicker.selectedDate.slice(0, 10));
          } else {
            const date = momentTz(dateTimePicker.selectedDate.slice(0, 10), {
              name: dateTimePicker.selectedTimezoneName,
            });
            date.set(convertTimeToObject(dateTimePicker.selectedTime));

            setAnswer(modal.questionIndex, date.utc().format());

            payload = date.utc().format();
          }
          break;
        }

        default:
          payload = answer;
      }

      const { sentAnswers } = modal;

      // eslint-disable-next-line no-async-promise-executor
      return new Promise<TResponse<unknown>>(async (resolve, reject) => {
        // If question has been answered, update it.
        if (sentAnswers[question.id]) {
          const answerId = sentAnswers[question.id].id;

          const response = await updateAnswer(
            `/api/questionnaires/${question.questionnaireId}/answers/${answerId}`,
            {
              answerGroupId: modal.answerGroupId,
              payload,
              // API requires questionId be present in the payload,
              // despite the fact that answerId is present in the url.
              questionId: question.id,
            }
          );

          if (response.status === AsyncStatusEnum.SUCCESS) {
            resolve(response);
          }

          if (response.status === AsyncStatusEnum.ERROR) {
            reject(response);
          }
        } else {
          startSendingAnswer();

          const response = await sendAnswer(question.questionnaireId, {
            answerGroupId: modal.answerGroupId,
            payload,
            questionId: question.id,
          });

          if (response.status === AsyncStatusEnum.SUCCESS) {
            resolve(response);
          }

          if (response.status === AsyncStatusEnum.ERROR) {
            reject(response);
          }
        }
      })
        .then((res) => {
          if (res.status === AsyncStatusEnum.SUCCESS) {
            if (modal.questionIndex === questionsList.length - 1) {
              if (
                questionnaire.settings?.isSummaryEnabled &&
                questionnaire.type !== QuestionnaireType.INSTANT_BOOK
              ) {
                showView(QuoteRequestScreenEnum.SUMMARY);
              } else {
                submitQuestionnaireForm();
              }
            } else {
              showNextQuestion();
            }
          }

          if (res.status === AsyncStatusEnum.ERROR) {
            showErrorMessageFromResponse(res);
          }
        })
        .finally(() => {
          stopSendingAnswer();
        });
    }

    // 2. Not required questions.
    if (modal.questionIndex === questionsList.length - 1) {
      if (
        questionnaire.settings?.isSummaryEnabled &&
        questionnaire.type !== QuestionnaireType.INSTANT_BOOK
      ) {
        showView(QuoteRequestScreenEnum.SUMMARY);
      } else {
        submitQuestionnaireForm();
      }
    } else {
      showNextQuestion();
    }
  }

  const hasBackButton =
    (modal.questionIndex !== 0 || questionnaire.introTitle || questionnaire.introText) &&
    !(prevQuestion && prevQuestion.type === QuestionType.BOOKING_PACKAGE);

  return (
    <div
      className="font-regular mx-auto w-full max-w-3xl px-6 py-12 md:py-20"
      data-testid={`${question.type.toLowerCase().replaceAll('_', '-')}-question`}
    >
      <QuoteRequestHeadline
        descriptionProps={{ dangerouslySetInnerHTML: { __html: escapeHTML(question.description) } }}
        headerProps={{ dangerouslySetInnerHTML: { __html: escapeHTML(question.title) } }}
      />

      <QuestionContent
        autoFocus={autoFocus}
        index={index}
        question={question}
        windowObj={windowObj}
      />

      <ButtonsContainer onNextClick={handleNextClick} windowObj={windowObj}>
        {hasBackButton ? (
          <QuoteRequestPrimaryButton
            data-testid="back-button"
            onClick={handleBackClick}
            variant="secondary"
          >
            Back
          </QuoteRequestPrimaryButton>
        ) : null}

        <QuoteRequestPrimaryButton
          data-testid="next-button"
          disabled={processing.length > 0 || queue.length > 0}
          loading={isAnswerSending || status === AsyncStatusEnum.PENDING || pending}
          onClick={handleNextClick}
        >
          Next <ArrowIcon />
        </QuoteRequestPrimaryButton>
      </ButtonsContainer>
    </div>
  );
};

export default Question;
