'use client';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { google, outlook, yahoo, ics, CalendarEvent } from 'calendar-link';

import { QuestionType } from '@bloom/codegen/models/QuestionType';
import { QuestionTypeDateResponse } from '@bloom/codegen/models/QuestionTypeDateResponse';

import { OptionsButton } from '@bloom/ui/components/OptionsButton';
import { useLocaleSettings } from '@bloom/ui/hooks/useLocaleSettings';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useMoment } from '@bloom/library/components/hooks/useMoment';
import { CalendarIconV2 } from '@bloom/library/components/Icon/CalendarV2';
import Loader from '@bloom/library/components/Loader';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';
import { getResponseErrorMessage } from '@bloom/library/components/Validator/utils';
import { convertTimeToObject } from '@bloom/library/utils/date';
import { isEmail } from '@bloom/library/utils/misc';
import { escapeHTML } from '@bloom/library/utils/string';

interface IProps {
  onClose: () => void;
  windowObj?: Window;
}

enum CalendarTypeEnum {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  OUTLOOK = 'OUTLOOK',
  YAHOO = 'YAHOO',
}

interface IDateSummary {
  addToCalendarUrls: Record<'google' | 'ical' | 'outlook' | 'yahoo', string>;
  date: string;
  event: {
    calendarId: string;
    description: string;
    endDate: string;
    id: string;
    isAllDay: boolean;
    isBusy: boolean;
    location: string;
    notes: string;
    startDate: string;
    title: string;
    type: string;
  };
  zoomMeetingUrl: string;
}

const Outro: React.FC<IProps> = (props) => {
  const { onClose, windowObj } = props;

  const [{ dateTimePicker, isPreview, modal, questionnaire }, { resetState, sendAnswer }] =
    useQuoteRequest();
  const { selectedDate, selectedTime, selectedTimezoneName } = dateTimePicker;
  const { completionActionButtonText, outroText, outroTitle, redirectUrl, settings } =
    questionnaire;

  const [isCompleted, setCompleted] = useState(isPreview);
  const { message, showErrorMessage } = useInfoMessage();

  const [dateSummary, setDateSummary] = useState<IDateSummary | null>(null);

  const isMountedRef = useRef(false);

  const dateQuestionIndex = useMemo(
    () => questionnaire.questions.findIndex((q) => q.type === QuestionType.DATE),
    [questionnaire.questions]
  );
  const dateQuestion = questionnaire.questions[dateQuestionIndex] as QuestionTypeDateResponse;

  const { moment, momentTz } = useMoment();
  const { localeSettings } = useLocaleSettings();

  const dateAnswer = dateQuestionIndex > -1 ? (modal.answers[dateQuestionIndex] as string) : null;

  const dateAnswerMoment = useMemo(() => {
    const timezone = { name: selectedTimezoneName };

    if (dateAnswer) {
      return momentTz(dateAnswer, timezone);
    }

    const date = momentTz(selectedDate.slice(0, 10), timezone);

    if (isPreview && (!date || !date.isValid())) {
      return moment();
    }

    if (selectedTime) {
      date.set(convertTimeToObject(selectedTime));
    }

    // Convert date from local time to UTC before saving.
    return date;
  }, [dateAnswer, isPreview, moment, momentTz, selectedDate, selectedTime, selectedTimezoneName]);

  const calendarMenuItems = useMemo(
    () => [
      {
        label: 'Google Calendar',
        value: CalendarTypeEnum.GOOGLE,
      },
      {
        label: 'ICal (Apple)',
        value: CalendarTypeEnum.APPLE,
      },
      {
        label: 'Outlook',
        value: CalendarTypeEnum.OUTLOOK,
      },
      {
        label: 'Yahoo',
        value: CalendarTypeEnum.YAHOO,
      },
    ],
    []
  );

  useEffect(() => {
    // Call it once on mount
    if (!isMountedRef.current && !isPreview) {
      sendAnswer(questionnaire.id, {
        answerGroupId: modal.answerGroupId,
        payload: 'SUBMIT',
      }).then((res) => {
        if (res.status === AsyncStatusEnum.SUCCESS) {
          setCompleted(true);

          if (Array.isArray(res.data?.answer?.summary)) {
            const dateSummary =
              res.data.answer.summary.find((item) => item.type === QuestionType.DATE) || null;

            setDateSummary(dateSummary?.details || null);
          }
        }

        if (res.status === AsyncStatusEnum.ERROR) {
          setCompleted(true);
          showErrorMessage(getResponseErrorMessage(res.error));
        }
      });
    }

    isMountedRef.current = true;
  }, [
    isMountedRef,
    questionnaire.id,
    modal.answerGroupId,
    sendAnswer,
    showErrorMessage,
    isPreview,
  ]);

  let buttonText = completionActionButtonText || 'Done';
  let title = outroTitle;
  let text = outroText;

  if (message) {
    buttonText = 'Close';
    title = 'Error.';
    text = message;
  }

  function handleButtonClick() {
    if (isPreview) {
      resetState();
    } else {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        onClose();
      }
    }
  }

  function handleCalendarPick(_: string, value: CalendarTypeEnum) {
    if (isPreview) {
      return;
    }

    const clientInfoQuestionIndex = questionnaire.questions.findIndex(
      (q) => q.type === QuestionType.PERSONAL_INFO
    );
    const clientInfoAnwer =
      clientInfoQuestionIndex > -1
        ? (modal.answers[clientInfoQuestionIndex] as unknown as Array<string>)
        : null;
    const clientEmail = clientInfoAnwer ? clientInfoAnwer.find((answer) => isEmail(answer)) : '';

    const [hours = '', minutes = ''] = (dateQuestion?.options.duration || '').split(':');
    const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);

    const calendarEvent: CalendarEvent = {
      allDay: !!dateQuestion?.options.allDay,
      description: questionnaire.description,
      duration: totalMinutes ? [totalMinutes, 'minute'] : [1, 'hour'],
      guests: clientEmail ? [clientEmail] : [],
      location: dateQuestion?.location || '',
      organizer: {
        email: questionnaire.owner.email,
        name: `${questionnaire.owner.firstName || ''} ${questionnaire.owner.lastName || ''}`.trim(),
      },
      start: dateAnswerMoment.utc(),
      title: questionnaire.title,
    };

    const anchor = document.createElement('a');
    anchor.target = '_blank';
    switch (value) {
      case CalendarTypeEnum.GOOGLE:
        anchor.href = dateSummary?.addToCalendarUrls?.google || google(calendarEvent);
        break;
      case CalendarTypeEnum.APPLE:
        anchor.href = dateSummary?.addToCalendarUrls?.ical || ics(calendarEvent);
        break;
      case CalendarTypeEnum.OUTLOOK:
        anchor.href = dateSummary?.addToCalendarUrls?.outlook || outlook(calendarEvent);
        break;
      case CalendarTypeEnum.YAHOO:
        anchor.href = dateSummary?.addToCalendarUrls?.yahoo || yahoo(calendarEvent);
        break;
    }

    document.body.appendChild(anchor);
    anchor.click();

    document.body.removeChild(anchor);
  }

  return (
    <div
      className="font-regular mx-auto flex w-full max-w-3xl flex-col px-6 py-12 md:py-20"
      data-testid="question-outro"
    >
      {isCompleted ? (
        <>
          <QuoteRequestHeadline
            className="mb-6"
            headerProps={{
              dangerouslySetInnerHTML: { __html: escapeHTML(title) },
              'data-testid': 'outro-title',
            }}
          />

          {text.split(/\n/).map((paragraph, index) =>
            paragraph ? (
              <p
                className="mt-2 text-black dark:text-white"
                dangerouslySetInnerHTML={{ __html: escapeHTML(paragraph) }}
                key={index}
                style={{
                  color: settings?.text?.body.color || '',
                  textAlign: settings?.alignment || 'center',
                }}
              />
            ) : (
              <br key={index} />
            )
          )}

          {dateAnswer ? (
            <div className="mx-auto mt-12 inline-flex items-center gap-2">
              <span className="text-grey inline-flex">
                <CalendarIconV2 />
              </span>
              {dateAnswerMoment.format(localeSettings.dateFormat)}
              {dateQuestion?.options.allDay ? null : (
                <>
                  <span className="text-grey">&bull;</span>
                  {dateAnswerMoment.format(localeSettings.timeFormat)} (
                  {dateAnswerMoment.format('z')})
                </>
              )}
            </div>
          ) : null}

          <ButtonsContainer onNextClick={onClose} windowObj={windowObj}>
            {dateAnswer ? (
              <OptionsButton
                Trigger={
                  <QuoteRequestPrimaryButton
                    className="group"
                    color={undefined}
                    data-testid="add-to-calendar-button"
                    variant="secondary"
                  >
                    <CalendarIconV2 width={16} />
                    Add to Calendar
                  </QuoteRequestPrimaryButton>
                }
                matchParentWidth
                menuItemClassName="!flex-row"
                menuItems={calendarMenuItems}
                onChange={handleCalendarPick}
              />
            ) : null}

            <QuoteRequestPrimaryButton data-testid="submit-button" onClick={handleButtonClick}>
              {buttonText}
            </QuoteRequestPrimaryButton>
          </ButtonsContainer>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export { Outro };
