import React from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { Textarea } from '@bloom/ui/components/Textarea';

import {
  useAnswer,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';

const TextualQuestion: React.FC<{ autoFocus?: boolean; index: number }> = (props) => {
  const { index, autoFocus } = props;

  const [
    {
      questionnaire: { settings },
    },
    { setAnswer },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  // ! Make sure the answer is always a string (the textarea is controlled)
  // If the form contains multiple same type questions
  // React optimizing the rendering of the component persists the
  // value of the input field from previous question in case if the new value is undefined.
  // Undefined converts the input field to uncontrolled.
  const answer = useAnswer<string | undefined>(index) || '';

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const { value } = e.target;

    setAnswer(index, value);
  }

  return (
    <Textarea
      autoFocus={autoFocus}
      className={twMerge(
        'w-full max-w-3xl',
        alignment === QuestionnaireAlignment.RIGHT ? 'ml-auto' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'mr-auto' : '',
        alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : ''
      )}
      data-testid="answer-textarea"
      label="Enter your answer"
      onChange={handleChange}
      placeholder="Enter your answer"
      style={{ minHeight: 150 }}
      value={answer}
    />
  );
};

export { TextualQuestion };
