import React from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { ModalFooter } from '@bloom/library/components/Modal/ModalFooter';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';

interface IProps {
  onClose: () => void;
}

const ConfirmClose: React.FC<IProps> = (props) => {
  const { onClose } = props;

  const [
    {
      questionnaire: { settings },
    },
    { showView },
  ] = useQuoteRequest();
  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  function handleCancelClick() {
    showView(QuoteRequestScreenEnum.QUESTION);
  }

  return (
    <div
      className={twMerge(
        'font-regular mx-auto w-full max-w-sm px-6 py-12 md:py-20',
        alignment === QuestionnaireAlignment.CENTER ? 'text-center' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'text-left' : '',
        alignment === QuestionnaireAlignment.RIGHT ? 'text-right' : ''
      )}
      data-testid="close-confirmation"
    >
      <QuoteRequestHeadline
        headerProps={{ dangerouslySetInnerHTML: { __html: 'ARE YOU SURE?' } }}
      />

      <p className="mt-12">
        If you close this page, your entries will be lost and you will need to start over.
      </p>
      <ModalFooter
        className={twMerge(
          'mt-12',
          alignment === QuestionnaireAlignment.CENTER ? '!justify-center' : '',
          alignment === QuestionnaireAlignment.RIGHT ? '!justify-end' : '',
          alignment === QuestionnaireAlignment.LEFT ? '!justify-start' : ''
        )}
      >
        <QuoteRequestPrimaryButton
          data-testid="cancel-button"
          onClick={handleCancelClick}
          variant="secondary"
        >
          Cancel
        </QuoteRequestPrimaryButton>
        <QuoteRequestPrimaryButton data-testid="close-button" onClick={onClose}>
          Close
        </QuoteRequestPrimaryButton>
      </ModalFooter>
    </div>
  );
};

export { ConfirmClose };
