import React, { useEffect, useMemo, useState } from 'react';

import { FieldArray, Form } from 'formik';
import * as yup from 'yup';

import { Input } from '@bloom/ui/components/Input';
import { emptyArray } from '@bloom/ui/utils/empty-value';

import { ContractBody } from '@bloom/library/components/Contract/ContractBody';
import { DynamicInput } from '@bloom/library/components/Form/DynamicInput';
import { FormState } from '@bloom/library/components/Form/FormState';
import { ArrowIcon } from '@bloom/library/components/Icon/Arrow';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import {
  useQuoteRequest,
  useSelectedPackage,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';
import { H2 } from '@bloom/library/components/Typography/H2';
import { IQuoteRequestInstantBooking } from '@bloom/library/store/types';
import { ContractTypeEnum } from '@bloom/library/types/contract';
import { ClientInfoFieldEnum } from '@bloom/library/types/templates';
import { createContractVariablesFromClientInfo } from '@bloom/library/utils/contracts';

interface IFormValues {
  customFields: Array<Record<'title' | 'value', string>>;
  signatureName: string;
}

interface IProps {
  isLoading: boolean;
  onBackClick: () => void;
  onNextClick: () => void;
  windowObj?: Window;
}

const validationSchema = yup.object({
  customFields: yup.array().of(yup.object({ value: yup.string().required() })),
  signatureName: yup.string().required(),
});

const Contracts: React.FC<IProps> = (props) => {
  const { isLoading, onBackClick, onNextClick, windowObj } = props;

  const [quoteRequest, { setInstantBookingSignatures }] = useQuoteRequest();

  const {
    instantBooking,
    isPreview,
    questionnaire: { owner },
  } = quoteRequest;
  const { clientInfo, contracts: instantBookingContracts } = instantBooking;

  const selectedPackage = useSelectedPackage();
  const selectedPackageContracts = selectedPackage.contracts || emptyArray;
  const clientInfoContractVariables = createContractVariablesFromClientInfo(clientInfo);

  const combinedContractVariables = owner.contractVariables.concat(clientInfoContractVariables);

  const [contractIndex, setContractIndex] = useState(0);

  useEffect(() => {
    return () => {
      setContractIndex(0);
    };
  }, []);

  function handleContractSign(values: {
    customFields: Array<Record<'title' | 'value', string>>;
    signatureName: string;
  }) {
    const nextInstantBookingContracts: IQuoteRequestInstantBooking['contracts'] =
      selectedPackageContracts.map((contract, i) => {
        if (i === contractIndex) {
          return {
            customFields: values.customFields,
            id: contract.id,
            signatures: [
              {
                company: clientInfo[ClientInfoFieldEnum.COMPANY],
                email: clientInfo[ClientInfoFieldEnum.EMAIL_ADDRESS],
                firstName: clientInfo[ClientInfoFieldEnum.FIRST_NAME],
                lastName: clientInfo[ClientInfoFieldEnum.LAST_NAME],
                phone: clientInfo[ClientInfoFieldEnum.PHONE_NUMBER],
                signatureName: values.signatureName || '',
              },
            ],
          };
        }

        return (
          instantBookingContracts[i] || {
            customFields: contract.customFields || emptyArray,
            id: contract.id,
            signatures: [
              {
                company: clientInfo[ClientInfoFieldEnum.COMPANY],
                email: clientInfo[ClientInfoFieldEnum.EMAIL_ADDRESS],
                firstName: clientInfo[ClientInfoFieldEnum.FIRST_NAME],
                lastName: clientInfo[ClientInfoFieldEnum.LAST_NAME],
                phone: clientInfo[ClientInfoFieldEnum.PHONE_NUMBER],
                signatureName: '',
              },
            ],
          }
        );
      });

    setInstantBookingSignatures(nextInstantBookingContracts);
  }

  function handleBackClick() {
    if (contractIndex > 0) {
      setContractIndex(contractIndex - 1);
    } else {
      onBackClick();
    }
  }

  function handleSubmit(value: IFormValues) {
    handleContractSign(value);

    if (contractIndex < selectedPackageContracts.length - 1) {
      setContractIndex(contractIndex + 1);
    } else {
      onNextClick();
    }
  }

  const contract = selectedPackageContracts[contractIndex];

  const firstName = clientInfo[ClientInfoFieldEnum.FIRST_NAME] || clientInfo.firstName;
  const lastName = clientInfo[ClientInfoFieldEnum.LAST_NAME] || clientInfo.lastName;
  const email = clientInfo[ClientInfoFieldEnum.EMAIL_ADDRESS] || clientInfo.email;

  const clientName = `${firstName || ''} ${lastName || ''}`.trim();

  const initialValues: IFormValues = useMemo(() => {
    if (contract) {
      return { customFields: contract.customFields || emptyArray, signatureName: '' };
    }

    return { customFields: [], signatureName: '' };
  }, [contract]);

  return (
    <div
      className="font-regular mx-auto w-full max-w-3xl px-6 py-12 md:py-20"
      data-testid="instant-booking-contracts"
    >
      <QuoteRequestHeadline
        descriptionProps={{
          dangerouslySetInnerHTML: { __html: 'Enter your legal name to complete your signature.' },
        }}
        headerProps={{ dangerouslySetInnerHTML: { __html: 'Contract' } }}
      />

      <div className="flex w-full max-w-3xl flex-col gap-6">
        <H2>{contract.title}</H2>

        {contract.type === ContractTypeEnum.FORM ? (
          <ContractBody
            body={contract.body}
            className="mt-3 space-y-3"
            contractVariables={combinedContractVariables}
          />
        ) : (
          <embed
            className="mt-3"
            src={contract.url}
            style={{ height: '60vh' }}
            type="application/pdf"
            width="100%"
          />
        )}

        <FormState<IFormValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={isPreview ? undefined : validationSchema}
        >
          {({ errors, handleChange, values }) => (
            <Form>
              {values.customFields.length > 0 ? (
                <section className="mt-12">
                  <H2>Additional Fields</H2>
                  <div className="mt-6 grid gap-6 sm:grid-cols-2">
                    <FieldArray name="customFields">
                      {() =>
                        values.customFields.map((field, index) => (
                          <Input
                            data-testid="custom-field-input"
                            invalid={!!errors.customFields?.[index]?.value}
                            key={field.title}
                            label={field.title}
                            name={`customFields.${index}.value`}
                            onChange={handleChange}
                            value={field.value}
                          />
                        ))
                      }
                    </FieldArray>
                  </div>
                </section>
              ) : null}

              <section className="mt-12">
                <H2>Signatures</H2>
                <div className="dashed-border relative mt-6 p-1">
                  <DynamicInput
                    className="!font-signature !rounded-none !text-2xl placeholder-shown:!bg-[#fffacd] focus:!bg-transparent"
                    data-testid="signature-input"
                    name="signatureName"
                    onChange={handleChange}
                    placeholder="Sign here"
                    value={values.signatureName}
                  />
                </div>
                <p className="mt-6 font-medium">
                  {isPreview ? clientName || '[Client name]' : clientName}
                </p>
                <p className="text-black-50 dark:text-white-50 mt-1 text-xs">
                  {isPreview ? email || '[Client Email]' : email}
                </p>
              </section>

              <ButtonsContainer windowObj={windowObj}>
                <QuoteRequestPrimaryButton
                  data-testid="back-button"
                  onClick={handleBackClick}
                  variant="secondary"
                >
                  Back
                </QuoteRequestPrimaryButton>
                <QuoteRequestPrimaryButton
                  data-testid="next-button"
                  disabled={!isPreview && !values.signatureName}
                  loading={isLoading}
                  type="submit"
                >
                  Next <ArrowIcon />
                </QuoteRequestPrimaryButton>
              </ButtonsContainer>
            </Form>
          )}
        </FormState>
      </div>
    </div>
  );
};

export { Contracts };
