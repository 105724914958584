import React, { useCallback, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';
import { QuestionnaireType } from '@bloom/codegen/models/QuestionnaireType';

import { Checkbox } from '@bloom/ui/components/Checkbox';
import { SelectBox } from '@bloom/ui/components/SelectBox';
import { TextSizeEnum } from '@bloom/ui/components/Typography/Text';
import { doNothing } from '@bloom/ui/utils/empty-value';

import {
  useAnswer,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { H2 } from '@bloom/library/components/Typography/H2';
import { IMultipleChoiceQuestion, ISpecialtyQuestion } from '@bloom/library/types/questionnaire';
import { escapeHTML } from '@bloom/library/utils/string';

const MultipleChoiceQuestion: React.FC<{
  index: number;
  question: IMultipleChoiceQuestion | ISpecialtyQuestion;
}> = (props) => {
  const { index, question } = props;
  const [
    {
      questionnaire,
      questionnaire: { settings },
    },
    { setAnswer },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const isMultiStep = useMemo(() => {
    if (questionnaire.type === QuestionnaireType.INSTANT_BOOK) {
      return true;
    }
    return settings.isMultiStep;
  }, [questionnaire.type, settings.isMultiStep]);

  const answer = useAnswer(index) as Array<string> | string;

  const allowMultiSelect = useMemo(() => {
    if (Array.isArray(question.options)) {
      return false;
    }

    if ('allowMultiSelect' in question.options) {
      return question.options.allowMultiSelect;
    }

    return false;
  }, [question.options]);

  const fields = useMemo(() => {
    if (Array.isArray(question.options)) {
      return question.options;
    }

    return question.options.fields;
  }, [question.options]);

  const handleChange = useCallback(
    (_: string, value: string) => {
      if (allowMultiSelect) {
        if ((answer || []).includes(value)) {
          setAnswer(
            index,
            answer.filter((v) => v !== value)
          );
        } else {
          setAnswer(index, [...(answer || []), value]);
        }
      } else {
        setAnswer(index, value);
      }
    },
    [allowMultiSelect, answer, index, setAnswer]
  );

  const layoutWithImages = typeof fields[0] === 'object';

  return (
    <div
      className={twMerge(
        'flex flex-col flex-wrap items-center gap-3 text-black md:flex-row md:items-start dark:text-white',
        alignment === QuestionnaireAlignment.RIGHT ? 'ml-auto justify-end' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'mr-auto justify-start' : '',
        alignment === QuestionnaireAlignment.CENTER ? 'mx-auto justify-center' : '',
        layoutWithImages ? '' : `w-full ${isMultiStep ? 'max-w-sm' : ''} flex-col`
      )}
    >
      {fields.map((option, i) => {
        let value = '';
        if (typeof option === 'string') {
          value = option;
        } else {
          value = option.value;
        }

        return (
          <SelectBox
            className={twMerge(
              'flex flex-col gap-3',
              layoutWithImages ? 'w-60' : 'w-full',
              layoutWithImages && option.imageUrl ? '' : 'py-2.5'
            )}
            data-testid="option"
            key={i}
            name=""
            onClick={handleChange}
            selected={allowMultiSelect ? (answer || []).includes(value) : value === answer}
            value={value}
          >
            {layoutWithImages && option.imageUrl ? (
              <img
                className="bg-black-5 dark:bg-white-5 aspect-square w-52 shrink-0 rounded-sm object-contain object-center"
                src={option.imageUrl}
              />
            ) : null}
            <Checkbox
              checked={allowMultiSelect ? (answer || []).includes(value) : value === answer}
              className="w-full items-center justify-between"
              data-testid=""
              label={
                <H2
                  as="p"
                  className="-order-1 min-w-0 break-words text-black dark:text-white"
                  dangerouslySetInnerHTML={{ __html: escapeHTML(value) }}
                  size={TextSizeEnum.SM}
                  title={value}
                />
              }
              onChange={doNothing}
              type={
                'allowMultiSelect' in question.options && question.options.allowMultiSelect
                  ? 'checkbox'
                  : 'radio'
              }
              value={value}
            />
          </SelectBox>
        );
      })}
    </div>
  );
};

export { MultipleChoiceQuestion };
