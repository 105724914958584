import React from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { AddressAutocomplete } from '@bloom/library/components/Form/AddressAutocomplete';
import {
  useAnswer,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';

const AddressQuestion: React.FC<{
  autoFocus?: boolean;
  index: number;
  windowObj?: Window;
}> = (props) => {
  const { index, autoFocus, windowObj = window } = props;

  const [
    {
      questionnaire: { settings },
    },
    { setAnswer },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  // ! Make sure the answer is always a string (the textarea is controlled)
  // If the form contains multiple same type questions
  // React optimizing the rendering of the component persists the
  // value of the input field from previous question in case if the new value is undefined.
  // Undefined converts the input field to uncontrolled.
  const answer = useAnswer<string | undefined>(index) || '';

  function handleInputChange(value: string) {
    setAnswer(index, value);
  }

  function handleAddressSelect(_: unknown, rawValue: google.maps.GeocoderResult) {
    setAnswer(index, rawValue.formatted_address);
  }

  return (
    <AddressAutocomplete
      autoFocus={autoFocus}
      className={twMerge(
        'w-full max-w-3xl',
        alignment === QuestionnaireAlignment.RIGHT ? 'ml-auto' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'mr-auto' : '',
        alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : ''
      )}
      data-testid="answer-input"
      onChange={handleInputChange}
      onSelect={handleAddressSelect}
      placeholder="Enter your address"
      root={windowObj?.document.body}
      value={answer}
    />
  );
};

export { AddressQuestion };
