import React, { useEffect, useMemo, useRef } from 'react';

import { format, parseISO } from 'date-fns';

import { AttachmentResponse } from '@bloom/codegen/models/AttachmentResponse';

import { IconButton } from '@bloom/ui/components/IconButton';
import { CloseIcon } from '@bloom/ui/components/Icons/Close';
import { IconWrapper } from '@bloom/ui/components/IconWrapper';
import { emptyArray } from '@bloom/ui/utils/empty-value';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import Dropzone from '@bloom/library/components/Form/Dropzone';
import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import {
  IFileUploaderStateItem,
  useFileUploader,
} from '@bloom/library/components/hooks/useFileUploader';
import { usePublicAccount } from '@bloom/library/components/hooks/usePublicAccount';
import { useStorageToken } from '@bloom/library/components/hooks/useTempCredentials';
import { FileIcon } from '@bloom/library/components/Icon/File';
import { PictureIconV2 } from '@bloom/library/components/Icon/PictureV2';
import { TrashIcon } from '@bloom/library/components/Icon/Trash';
import {
  useAnswer,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { getCookie } from '@bloom/library/utils/browser';
import { humanFileSize } from '@bloom/library/utils/misc';

interface IFilePropsBase {
  customColor: string;
  index: number;
}

interface IFileUploadedProps extends IFilePropsBase {
  file: AttachmentResponse;
  onDelete: (index: number) => void;
}

interface IFileProcessingProps extends IFilePropsBase {
  uploadingItem: IFileUploaderStateItem;
}

interface IFileFailedProps extends IFilePropsBase {
  failedItem: IFileUploaderStateItem;
  onDelete: (fileId: string) => void;
}

const FileItem: React.FC<IFileUploadedProps | IFileProcessingProps | IFileFailedProps> = (
  props
) => {
  const { customColor, index } = props;

  const fileName = useMemo(() => {
    if ('file' in props) {
      return props.file.title;
    }

    if ('uploadingItem' in props) {
      return props.uploadingItem.file.name;
    }

    if ('failedItem' in props) {
      return props.failedItem.file.name;
    }

    return '';
  }, [props]);

  const fileSize = useMemo(() => {
    if ('file' in props) {
      return props.file.size;
    }

    if ('uploadingItem' in props) {
      return props.uploadingItem.file.size;
    }

    if ('failedItem' in props) {
      return props.failedItem.file.size;
    }

    return '';
  }, [props]);

  const fileDate = useMemo(() => {
    if ('file' in props) {
      return props.file.date;
    }

    return '';
  }, [props]);

  const progress = useMemo(() => {
    if ('uploadingItem' in props) {
      return props.uploadingItem.progress;
    }

    if ('failedItem' in props) {
      return props.failedItem.progress;
    }

    return undefined;
  }, [props]);

  function handleDeleteClick() {
    if ('uploadingItem' in props) {
      props.uploadingItem.xhr.abort();
    } else if ('failedItem' in props) {
      props.onDelete(props.failedItem.fileId);
    } else {
      props.onDelete(index);
    }
  }

  return (
    <div className="border-black-50 dark:border-white-50 relative flex items-center gap-3 rounded-lg border p-3">
      <IconWrapper style={{ color: '#FFF' }}>
        <FileIcon />
      </IconWrapper>
      <div className="flex min-w-0 flex-1 flex-col">
        <p className="truncate font-medium">
          {`${fileName} ${fileSize ? `(${humanFileSize(fileSize)})` : ''}`}
        </p>
        <div className="text-black-50 dark:text-white-50 text-xs">
          {fileDate
            ? format(parseISO(fileDate), 'MMM do, yyyy')
            : 'failedItem' in props
              ? 'Failed'
              : 'Uploading...'}
        </div>
      </div>
      <div>
        <IconButton data-testid="remove-file-button" onClick={handleDeleteClick}>
          {'file' in props || 'failedItem' in props ? <TrashIcon /> : <CloseIcon />}
        </IconButton>
      </div>

      {progress ? (
        <div className="absolute right-3 bottom-0 left-3">
          <div
            className="h-0.5 rounded-sm"
            style={{
              backgroundColor: 'failedItem' in props ? 'var(--color-background-red)' : customColor,
              width: `${progress}%`,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

const AttachmentQuestion: React.FC<{
  index: number;
  isPreview?: boolean;
  onUploadStatusChange?: (questionIndex: number, isUploading: boolean) => void;
}> = (props) => {
  const { index, isPreview, onUploadStatusChange } = props;
  const { showErrorMessage } = useInfoMessage();

  const dropzoneRef = useRef<Dropzone>(null);

  const { getStorageToken } = useStorageToken();

  const [
    {
      modal: { answerGroupId },
      questionnaire: { accountId, settings },
    },
    { setAnswer },
  ] = useQuoteRequest();

  const answer = (useAnswer(index) as Array<AttachmentResponse>) || emptyArray;

  const answerStateRef = useRef<Array<Omit<AttachmentResponse, 'id' | 'ownerId'>>>(
    answer || emptyArray
  );

  const authToken = getCookie('bloom_token');
  const { account } = usePublicAccount(accountId);
  const customColor = useCustomColor(settings?.button?.color || account?.settings?.customColor);

  useEffect(() => {
    if (answerGroupId && !authToken) {
      getStorageToken({ email: `${answerGroupId}@bloom-questionnaire.io` });
    }
  }, [answerGroupId, authToken, getStorageToken]);

  const handleFileUploadSuccess = (response: string) => {
    try {
      const { file } = JSON.parse(response);
      // Use reference rather than state or context to avoid a race condition
      // when the callback triggers multiple times with the same old state
      // before it re-render at least once, so we are loosing files
      answerStateRef.current = [
        ...answerStateRef.current,
        {
          date: file.createdAt,
          mimeType: file.mimeType,
          size: file.fileSize,
          title: file.originalName,
          url: file.path,
        },
      ];

      setAnswer(index, answerStateRef.current);

      return Promise.resolve(file);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const {
    failed = emptyArray,
    processing = emptyArray,
    queue = emptyArray,
    removeFile,
    selectFiles,
  } = useFileUploader({
    destination: `questionnaire-form-upload-step-${index}`,
    onSuccess: handleFileUploadSuccess,
  });

  useEffect(() => {
    if (typeof onUploadStatusChange === 'function') {
      onUploadStatusChange(index, processing.length > 0 || queue.length > 0);
    }
  }, [index, onUploadStatusChange, processing.length, queue.length]);

  const numberOfSelectedFiles = answerStateRef.current.length + processing.length + queue.length;

  function handleFileSelect(files: File[]) {
    const numberOfNewFiles = files.length;

    if (numberOfNewFiles + numberOfSelectedFiles > 5) {
      showErrorMessage("You can't upload more than 5 files");
    }

    selectFiles(Array.from(files).slice(0, Math.max(5 - numberOfSelectedFiles)));

    dropzoneRef.current.fileInput.value = '';
  }

  function handleDeleteClick(idx: number) {
    answerStateRef.current = [
      ...answerStateRef.current.slice(0, idx),
      ...answerStateRef.current.slice(idx + 1),
    ];

    setAnswer(index, [...answerStateRef.current]);
  }

  function handleRemoveFailedFile(fileId: string) {
    removeFile(fileId);
  }

  return (
    <>
      <div className="space-y-3">
        {React.Children.toArray(
          answer.map((file, idx) => (
            <FileItem
              customColor={customColor}
              file={file}
              index={idx}
              onDelete={handleDeleteClick}
            />
          ))
        )}
        {React.Children.toArray(
          [...processing, ...queue].map((item, idx) => (
            <FileItem customColor={customColor} index={idx} uploadingItem={item} />
          ))
        )}
        {React.Children.toArray(
          failed.map((item, idx) => (
            <FileItem
              customColor={customColor}
              failedItem={item}
              index={idx}
              onDelete={handleRemoveFailedFile}
            />
          ))
        )}
      </div>

      {numberOfSelectedFiles < 5 ? (
        <div className="border-black-50 dark:border-white-50 relative mt-6 flex h-30 flex-col items-center justify-center rounded-sm border border-dashed">
          <Dropzone
            className="!absolute inset-0 z-10 border-none"
            isDisabled={isPreview}
            isMultiple
            onUpload={handleFileSelect}
            ref={dropzoneRef}
          />

          <div className="text-center text-xs">
            <span className="text-black-50 dark:text-white-50">
              <PictureIconV2 className="mx-auto mb-3" color="currentColor" />
            </span>
            <div>
              Drag image here or <br /> <u>click to upload</u>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { AttachmentQuestion };
