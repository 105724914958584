'use client';
import { useCallback, useMemo, useState } from 'react';

import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';
import { QuestionType } from '@bloom/codegen/models/QuestionType';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { validateForm } from '@bloom/library/components/Form/utils';
import { AsyncStatusEnum, useFetch } from '@bloom/library/components/hooks/useFetch';
import { useMoment } from '@bloom/library/components/hooks/useMoment';
import { Outro } from '@bloom/library/components/QuoteRequest/views/Questions/Outro';
import { IQuestion } from '@bloom/library/types/questionnaire';
import { convertTimeToObject } from '@bloom/library/utils/date';
import { escapeHTML } from '@bloom/library/utils/string';

import { QuoteRequestScreenEnum } from './actions';
import { QuoteRequestHeadline } from './components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from './components/QuoteRequestPrimaryButton';
import { useQuestionnaireSubmit, useQuestions, useQuoteRequest } from './quote-request-context';
import { QuestionContent } from './views/Question';
import { Intro } from './views/Questions/Intro';
import { Summary } from './views/Questions/Summary';

interface IProps {
  documentObj?: Document;
  onClose: () => void;
  windowObj?: Window;
}

const SingleStepForm: React.FC<IProps> = (props) => {
  const { documentObj = document, onClose, windowObj } = props;
  const questionsList = useQuestions();

  const { post, status } = useFetch();
  const { momentTz } = useMoment();

  const { showErrorMessage, showErrorMessageFromResponse } = useInfoMessage();

  const [{ dateTimePicker, isPreview, modal, questionnaire }, { setAnswer, setErrors, showView }] =
    useQuoteRequest();

  const { pending, submitQuestionnaireForm } = useQuestionnaireSubmit();

  const validationSchema = useMemo(() => {
    const schemas = questionsList.reduce((acc: yup.ObjectShape, question, index: number) => {
      if (question.type === QuestionType.CONTENT) {
        return acc;
      }

      if (question.type === QuestionType.PERSONAL_INFO) {
        return {
          ...acc,
          [index]: yup.object({
            ...question.options.reduce(
              (acc: yup.ObjectShape, option: string) => ({
                ...acc,
                [option]: yup.string().label(option).required(),
              }),
              {}
            ),
          }),
        };
      }

      if (question.type === QuestionType.DATE) {
        if (question.isRequired) {
          return {
            ...acc,
            [index]: yup.object({
              selectedDate: yup.string().required(),
              selectedTime: question.options.allDay ? yup.string() : yup.string().required(),
            }),
          };
        }
        return acc;
      }

      if (question.isRequired) {
        if (question.type === QuestionType.ATTACHMENT) {
          return {
            ...acc,
            [index]: yup.array().min(1).required(),
          };
        }
        return {
          ...acc,
          [index]: yup.lazy((value) =>
            Array.isArray(value) ? yup.array().of(yup.string()).min(1) : yup.mixed().required()
          ),
        };
      }
      return acc;
    }, {} as yup.ObjectShape);

    return yup.object(schemas);
  }, [questionsList]);

  const { answerGroupId, answers, currentView } = modal;

  const { settings } = questionnaire;
  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const [uploadImagesStatus, setUploadImagesStatus] = useState<{ [index: number]: boolean }>({});

  const isUploadingFiles = useMemo(
    () => Object.values(uploadImagesStatus).some((v) => v),
    [uploadImagesStatus]
  );

  const handleUploadFilesChange = useCallback(
    (questionIndex: number, isUploading: boolean) => {
      setUploadImagesStatus((state) => ({ ...state, [questionIndex]: isUploading }));
    },
    [setUploadImagesStatus]
  );

  async function handleNextClick() {
    if (isPreview) {
      submitQuestionnaireForm();
      return;
    }

    const values = Object.assign({}, answers);
    const dateQuestionIndex = questionsList.findIndex(
      (question) => question.type === QuestionType.DATE
    );

    if (dateQuestionIndex > -1) {
      values[dateQuestionIndex] = {
        selectedDate: dateTimePicker.selectedDate,
        selectedTime: dateTimePicker.selectedTime,
      };
    }

    const personalInfoIndex = questionsList.findIndex(
      (question) => question.type === QuestionType.PERSONAL_INFO
    );

    if (personalInfoIndex > -1) {
      values[personalInfoIndex] = questionsList[personalInfoIndex].options.reduce(
        (computedAnswer, name, fieldIndex) => {
          return { ...computedAnswer, [name]: values[personalInfoIndex]?.[fieldIndex] };
        },
        {}
      );
    }

    const { errors } = validateForm(Object.assign({}, values), validationSchema);
    setErrors(errors);

    if (Object.keys(errors).length) {
      const [firstErrorIndex] = Object.keys(errors);
      const questionHeader = documentObj.getElementById(
        `quote-request-headline-${questionnaire.id}-${firstErrorIndex}`
      );

      questionHeader?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });

      showErrorMessage('Please complete the form before submitting.');
      return;
    }

    const payload = questionsList.reduce((acc, question, questionIndex) => {
      if (question.type === QuestionType.CONTENT) {
        return acc;
      }
      if (question.type === QuestionType.PERSONAL_INFO) {
        return [...acc, { answerGroupId, payload: values[questionIndex], questionId: question.id }];
      }

      if (question.type === QuestionType.DATE) {
        const selectedDate = values[questionIndex]?.selectedDate?.slice(0, 10);
        if (question.options.allDay) {
          setAnswer(questionIndex, selectedDate);
          return [...acc, { answerGroupId, payload: selectedDate, questionId: question.id }];
        }

        const date = momentTz(dateTimePicker.selectedDate.slice(0, 10), {
          name: dateTimePicker.selectedTimezoneName,
        });
        date.set(convertTimeToObject(dateTimePicker.selectedTime));

        setAnswer(modal.questionIndex, date.utc().format());

        const answer = date.utc().format();
        setAnswer(questionIndex, answer);

        return [...acc, { answerGroupId, payload: answer, questionId: question.id }];
      }

      return [...acc, { answerGroupId, payload: values[questionIndex], questionId: question.id }];
    }, []);

    const res = await post(
      `/api/questionnaires/${questionnaire.id}/answer-groups/${answerGroupId}`,
      { answers: payload }
    );

    if (res.status === AsyncStatusEnum.ERROR) {
      showErrorMessageFromResponse(res);
    }

    if (res.status === AsyncStatusEnum.SUCCESS) {
      if (settings.isSummaryEnabled) {
        showView(QuoteRequestScreenEnum.SUMMARY);
      } else {
        submitQuestionnaireForm();
      }
    }
  }

  if (currentView === QuoteRequestScreenEnum.SUMMARY) {
    return <Summary windowObj={windowObj} />;
  }

  if (currentView === QuoteRequestScreenEnum.OUTRO) {
    return <Outro onClose={onClose} windowObj={windowObj} />;
  }

  return (
    <div className="mx-auto flex w-full max-w-3xl flex-col gap-9 px-6 py-12">
      <Intro windowObj={windowObj} />

      {questionsList.map((question, index) => {
        const title = escapeHTML(question.title) ? escapeHTML(question.title) : '';
        return (
          <>
            <div>
              <QuoteRequestHeadline
                className={twMerge(question.description || question.title ? 'mb-6' : '')}
                descriptionProps={{
                  dangerouslySetInnerHTML: { __html: escapeHTML(question.description) },
                }}
                headerProps={{
                  dangerouslySetInnerHTML: {
                    __html: title
                      ? `${title}${question.isRequired ? '\u0020<sup class="font-regular text-base">*</sup>' : ''}`
                      : '',
                  },
                }}
                id={`quote-request-headline-${questionnaire.id}-${index}`}
              />

              <QuestionContent
                autoFocus={false}
                index={index}
                isPreview={isPreview}
                key={question.id}
                onUploadStatusChange={handleUploadFilesChange}
                question={question as IQuestion}
                windowObj={windowObj}
              />
            </div>
          </>
        );
      })}
      <QuoteRequestPrimaryButton
        className={twMerge(
          alignment === QuestionnaireAlignment.RIGHT ? 'self-end' : '',
          alignment === QuestionnaireAlignment.LEFT ? 'self-start' : '',
          alignment === QuestionnaireAlignment.CENTER ? 'self-center' : ''
        )}
        data-testid="next-button"
        disabled={isUploadingFiles}
        loading={status === AsyncStatusEnum.PENDING || pending}
        onClick={handleNextClick}
      >
        Submit
      </QuoteRequestPrimaryButton>
    </div>
  );
};

export { SingleStepForm };
