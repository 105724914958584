import React, { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';
import { QuestionnaireType } from '@bloom/codegen/models/QuestionnaireType';

import { Text } from '@bloom/ui/components/Typography/Text';

import { ArrowIcon } from '@bloom/library/components/Icon/Arrow';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';
import { escapeHTML } from '@bloom/library/utils/string';

interface IProps {
  windowObj?: Window;
}

const Intro: React.FC<IProps> = (props) => {
  const { windowObj } = props;

  const [{ questionnaire }, { showView }] = useQuoteRequest();
  const { introText, introTitle, settings } = questionnaire;

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const isMultiStep = useMemo(() => {
    if (questionnaire.type === QuestionnaireType.INSTANT_BOOK) {
      return true;
    }
    return settings.isMultiStep;
  }, [questionnaire.type, settings.isMultiStep]);

  function handleNextClick() {
    showView(QuoteRequestScreenEnum.QUESTION);
  }

  if (!introText.trim() && !introTitle.trim()) {
    return null;
  }

  return (
    <div
      className={twMerge('font-regular max-w-3xl', isMultiStep ? 'py-12 md:py-20' : '')}
      data-testid="question-intro"
    >
      <div
        className={twMerge(
          'w-full',
          alignment === QuestionnaireAlignment.RIGHT ? 'ml-auto' : '',
          alignment === QuestionnaireAlignment.LEFT ? 'mr-auto' : '',
          alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : ''
        )}
      >
        <QuoteRequestHeadline
          className="mb-6"
          headerProps={{ dangerouslySetInnerHTML: { __html: escapeHTML(introTitle) } }}
        />

        <div
          className="space-y-3 text-black dark:text-white"
          style={{ color: settings?.text?.body.color || '' }}
        >
          {introText
            .split(/\n/)
            .map((paragraph, index) =>
              paragraph ? (
                <Text
                  dangerouslySetInnerHTML={{ __html: escapeHTML(paragraph) }}
                  key={index}
                  style={{ textAlign: alignment }}
                />
              ) : (
                <br key={index} />
              )
            )}
        </div>

        {isMultiStep ? (
          <ButtonsContainer onNextClick={handleNextClick} windowObj={windowObj}>
            <QuoteRequestPrimaryButton data-testid="next-button" onClick={handleNextClick}>
              Next <ArrowIcon />
            </QuoteRequestPrimaryButton>
          </ButtonsContainer>
        ) : null}
      </div>
    </div>
  );
};

export { Intro };
