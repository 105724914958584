import React, { useEffect } from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { useBookingPackages } from '@bloom/library/components/hooks/useBookingPackages';
import { ArrowIcon } from '@bloom/library/components/Icon/Arrow';
import Loader from '@bloom/library/components/Loader';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import {
  useCurrentQuestion,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';
import { Package } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion/Package';
import { IBookingPackageQuestion } from '@bloom/library/types/questionnaire';
import { escapeHTML } from '@bloom/library/utils/string';

interface IProps {
  documentObj?: Document;
  onBackClick?: () => void;
  onNextClick: () => void;
  windowObj?: Window;
}

const PackagesList: React.FC<IProps> = (props) => {
  const { documentObj, onBackClick, onNextClick, windowObj } = props;

  const [
    {
      instantBooking,
      questionnaire: { owner, settings },
    },
    { selectInstantBookingPackage },
  ] = useQuoteRequest();
  const { selectedPackageId } = instantBooking;

  const { data: bookingPackages, isFetching } = useBookingPackages(owner.id);

  const { showErrorMessage } = useInfoMessage();
  const question = useCurrentQuestion() as IBookingPackageQuestion;

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const packages = Object.values(question.options.bookingPackageIds || {})
    .filter((id) => !!bookingPackages[id])
    .map((id) => bookingPackages[id]);

  useEffect(() => {
    if (packages.length === 1 && !selectedPackageId && packages[0]) {
      selectInstantBookingPackage(packages[0].id);
    }
  }, [packages, selectInstantBookingPackage, selectedPackageId]);

  let title = 'Packages';
  if (packages.length === 0) {
    title = 'Please select at least one package to enable instant booking.';
  } else if (question.title) {
    title = question.title;
  }

  function handleNextClick() {
    if (selectedPackageId) {
      onNextClick();
    } else {
      showErrorMessage('Please select a package');
    }
  }

  return (
    <div className="font-regular container py-12 md:py-20" data-testid="instant-booking-packages">
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <QuoteRequestHeadline
            descriptionProps={{
              dangerouslySetInnerHTML: { __html: escapeHTML(question.description) },
            }}
            headerProps={{ dangerouslySetInnerHTML: { __html: escapeHTML(title) } }}
          />

          <div
            className={twMerge(
              'relative z-10 flex w-full flex-wrap gap-6',
              alignment === QuestionnaireAlignment.CENTER ? 'justify-center' : '',
              alignment === QuestionnaireAlignment.RIGHT ? 'justify-end' : '',
              alignment === QuestionnaireAlignment.LEFT ? 'justify-start' : ''
            )}
          >
            {packages.map((p) => (
              <Package
                data-testid="package-item"
                documentObj={documentObj}
                isSelected={p.id === selectedPackageId}
                key={p.id}
                pkg={p}
                windowObj={windowObj}
              />
            ))}
          </div>

          <ButtonsContainer onNextClick={handleNextClick} windowObj={windowObj}>
            {onBackClick ? (
              <QuoteRequestPrimaryButton
                data-testid="back-button"
                onClick={onBackClick}
                variant="secondary"
              >
                Back
              </QuoteRequestPrimaryButton>
            ) : null}
            <QuoteRequestPrimaryButton data-testid="next-button" onClick={handleNextClick}>
              Next <ArrowIcon />
            </QuoteRequestPrimaryButton>
          </ButtonsContainer>
        </>
      )}
    </div>
  );
};
export { PackagesList };
