import React, { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import { renderTiptapToHtml } from '@bloom/library/components/Tiptap/template.server';
import { IContentQuestion } from '@bloom/library/types/questionnaire';

const ContentQuestion: React.FC<{ question: IContentQuestion }> = (props) => {
  const { question } = props;
  const [
    {
      questionnaire: { settings },
    },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const renderedContent = useMemo(() => {
    try {
      const renderedBody = renderTiptapToHtml(question.options.body);
      return renderedBody;
    } catch {}

    return typeof question.options.body === 'string' ? question.options.body : '';
  }, [question.options.body]);

  return (
    <div
      className={twMerge(
        'flex flex-col gap-6',
        alignment === QuestionnaireAlignment.CENTER ? 'items-center text-center' : '',
        alignment === QuestionnaireAlignment.RIGHT ? 'items-end text-right' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'items-start text-left' : ''
      )}
    >
      <div
        className="text-sm text-black dark:text-white"
        dangerouslySetInnerHTML={{ __html: renderedContent }}
        id="bloom-wysiwyg"
        style={{ color: settings?.text?.body.color || '' }}
      />
      {question.options.images.length > 0 ? (
        <div className="flex max-w-md flex-col gap-6">
          {question.options.images.map((image) => (
            <img key={image} src={image} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export { ContentQuestion };
