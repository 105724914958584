'use client';
import React, { useEffect, useMemo, useRef } from 'react';

import { QuestionType } from '@bloom/codegen/models/QuestionType';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useMoment } from '@bloom/library/components/hooks/useMoment';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import {
  useCurrentQuestion,
  useQuestionnaireSubmit,
  useQuestions,
  useQuoteRequest,
  useSelectedPackage,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { AddonsList } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion/AddonsList';
import { ClientInfo } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion/ClientInfo';
import { Contracts } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion/Contracts';
import { DateStep } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion/DateStep';
import { PackagesList } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion/PackagesList';
import { Payment } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion/Payment';
import { IBookingPackageQuestion } from '@bloom/library/types/questionnaire';
import { ClientInfoFieldEnum } from '@bloom/library/types/templates';
import { convertTimeToObject } from '@bloom/library/utils/date';
import { isEmail } from '@bloom/library/utils/misc';

const defaultClientInfoOptions = [
  ClientInfoFieldEnum.FIRST_NAME,
  ClientInfoFieldEnum.LAST_NAME,
  ClientInfoFieldEnum.EMAIL_ADDRESS,
  ClientInfoFieldEnum.PHONE_NUMBER,
];

interface IProps {
  documentObj?: Document;
  onClose: () => void;
  windowObj?: Window;
}

const InstantBookingQuestion: React.FC<IProps> = (props) => {
  const { documentObj = document, onClose, windowObj = window } = props;

  const { hideMessage, showErrorMessage, showErrorMessageFromResponse } = useInfoMessage();

  const { moment, momentTz } = useMoment();

  const [isSubmitting, { setFalse: stopSubmitting, setTrue: startSubmitting }] = useToggle();

  const [
    quoteRequest,
    {
      initInstantBookingStep,
      resetAnswerGroupId,
      sendAnswer,
      setInstantBookingClientInfo,
      setInstantBookingStep,
      showNextQuestion,
      showPrevQuestion,
      showView,
    },
  ] = useQuoteRequest();

  const { submitQuestionnaireForm } = useQuestionnaireSubmit();

  const selectedPackage = useSelectedPackage();

  const { dateTimePicker, instantBooking, isPreview, modal, questionnaire } = quoteRequest;

  const instantBookingRef = useRef(instantBooking);
  useEffect(() => {
    instantBookingRef.current = instantBooking;
  }, [instantBooking]);

  const { selectedDate, selectedTime, selectedTimezoneName } = dateTimePicker;

  const { questionIndex } = modal;
  const questions = useQuestions();

  const instantBookingQuestion = useMemo(
    () => questions.find((q) => q.type === QuestionType.BOOKING_PACKAGE),
    [questions]
  );

  const allDay = instantBookingQuestion?.options?.allDay;

  const currentQuestion = useCurrentQuestion();

  const isClientInfoBeforeInstantBooking = useMemo(() => {
    const instantBookingIndex = questionnaire.questions.findIndex(
      (q) => q.type === QuestionType.BOOKING_PACKAGE
    );
    const clientInfoIndex = questionnaire.questions.findIndex(
      (q) => q.type === QuestionType.PERSONAL_INFO
    );
    return clientInfoIndex < instantBookingIndex;
  }, [questionnaire.questions]);

  useEffect(() => {
    if (isClientInfoBeforeInstantBooking) {
      initInstantBookingStep('client_info');
    }
  }, [initInstantBookingStep, isClientInfoBeforeInstantBooking]);

  function handleLastNextClick() {
    if (questionIndex < questions.length - 1) {
      showNextQuestion();
    } else if (questionnaire.settings.isCustomConfirmationEnabled) {
      submitQuestionnaireForm();
    } else if (questionnaire.outroText) {
      showView(QuoteRequestScreenEnum.OUTRO);
    } else {
      resetAnswerGroupId();
      onClose();
    }
  }

  function getEventDate() {
    if ((allDay && !selectedDate) || (!allDay && (!selectedDate || !selectedTime))) {
      return '';
    }

    const timezone = { name: selectedTimezoneName };

    const date = momentTz(selectedDate.slice(0, 10), timezone);

    if (allDay) {
      return moment(selectedDate.slice(0, 10));
    }

    date.set(convertTimeToObject(selectedTime));
    // Convert date from local time to UTC before saving.
    return date.utc().format();
  }

  function handleAnswerSubmit() {
    // User mutable ref object rather that state due to race condition
    // when the callback triggers before the state is updated
    // https://app.clickup.com/t/8685ub8a1
    const { addons, clientInfo, contracts, selectedPackageId } = instantBookingRef.current;

    const payload = {
      addons: addons.map((addon) => ({ quantity: addon.quantity, slug: addon.slug })),
      bookingPackageId: selectedPackageId,
      clientInfo,
      contracts,
      eventDate: getEventDate(),
    };

    startSubmitting();

    sendAnswer(currentQuestion.questionnaireId, {
      answerGroupId: modal.answerGroupId,
      payload,
      questionId: currentQuestion.id,
    })
      .then((res) => {
        if (res.status === AsyncStatusEnum.SUCCESS) {
          handleLastNextClick();
        }

        if (res.status === AsyncStatusEnum.ERROR) {
          showErrorMessageFromResponse(res);
        }
      })
      .finally(stopSubmitting);
  }

  function showNextStep(step: string) {
    let nextStep = step;

    const { step: prevStep } = instantBooking;

    const { schedulingEnabled = true } = (currentQuestion as IBookingPackageQuestion).options;
    const hasAddons = !!selectedPackage && selectedPackage.package.addons.length > 0;
    const hasContracts = (selectedPackage?.contracts?.length || 0) > 0;

    if (nextStep === 'addons') {
      if (hasAddons) {
        // continue to show Addons view
      } else {
        nextStep = 'date';
      }
    }

    if (nextStep === 'date') {
      if (schedulingEnabled) {
        // continue to show Date view
      } else {
        nextStep = 'client_info';
      }
    }

    if (nextStep === 'client_info') {
      if (!isClientInfoBeforeInstantBooking) {
        // continue to show Client Info view
      } else {
        nextStep = 'contracts';
      }
    }

    if (nextStep === 'contracts') {
      if (prevStep === 'client_info' && isClientInfoBeforeInstantBooking) {
        nextStep = 'packages';
      } else if (hasContracts) {
        nextStep = 'contracts';
      } else {
        nextStep = 'payment';
      }
    }

    if (nextStep === 'payment') {
      const hasPayment =
        !!selectedPackage && (!selectedPackage.bookWithoutPayment || selectedPackage.achPayment);

      if (hasPayment) {
        // continue to show Payment view
      } else {
        if (isPreview) {
          handleLastNextClick();
        } else {
          setTimeout(() => {
            // Don't really like the hack. Need to find a better solution.
            // Wait for the next tick due to race condition
            // when the callback triggers before the state is updated
            // https://app.clickup.com/t/8685ub8a1
            handleAnswerSubmit();
          });
        }

        return;
      }
    }

    setInstantBookingStep(nextStep, prevStep);
  }

  function showPrevStep() {
    const { stepHistory } = instantBooking;
    const [step] = stepHistory.slice(-1);
    // Moving backward, no need to provide prev step.
    setInstantBookingStep(step);
  }

  function handleClientInfoChange(key: string, value: string) {
    const { clientInfo } = instantBooking;
    setInstantBookingClientInfo({ ...clientInfo, [key]: value });
  }

  function handleClientInfoStepFinish() {
    if (isPreview) {
      showNextStep('contracts');
      return;
    }

    const { clientInfo } = instantBooking;
    const question = questionnaire.questions.find((q) => q.type === QuestionType.PERSONAL_INFO);
    const options =
      question?.type === QuestionType.PERSONAL_INFO ? question.options : defaultClientInfoOptions;

    let hasEmptyFields = false;

    let isEmailValid = true;
    for (let i = 0; i < options.length; i += 1) {
      const key = options[i];
      const value = clientInfo[key] || '';
      if (!value) {
        hasEmptyFields = true;
      }
      if (/email/gi.test(key) && !isEmail(value)) {
        isEmailValid = false;
      }
    }

    if (hasEmptyFields) {
      showErrorMessage('All fields are required.');
      return;
    }
    if (!isEmailValid) {
      showErrorMessage('Please, provide a valid email address.');
      return;
    }

    hideMessage();

    showNextStep('contracts');
  }

  function handleFirstBackClick() {
    const { stepHistory } = instantBooking;

    if (stepHistory.length > 0) {
      showPrevStep();
    } else if (modal.questionIndex === 0) {
      showView(QuoteRequestScreenEnum.INTRO);
    } else {
      showPrevQuestion();
    }
  }

  function renderStep() {
    const { clientInfo, step, stepHistory } = instantBooking;
    const { errorMessage, questionIndex } = modal;

    const isBackButtonEnabled =
      questionIndex !== 0 || questionnaire.introText || stepHistory.length > 0;

    switch (step) {
      case 'packages':
        return (
          <PackagesList
            documentObj={documentObj}
            onBackClick={isBackButtonEnabled ? handleFirstBackClick : undefined}
            onNextClick={showNextStep.bind(null, 'addons')}
            windowObj={windowObj}
          />
        );
      case 'addons':
        return (
          <AddonsList
            onBackClick={showPrevStep}
            onNextClick={showNextStep.bind(null, 'date')}
            windowObj={windowObj}
          />
        );
      case 'date':
        return (
          <DateStep
            onBackClick={showPrevStep}
            onNextClick={showNextStep.bind(null, 'client_info')}
          />
        );
      case 'client_info':
        return (
          <ClientInfo
            answer={clientInfo}
            defaultOptions={defaultClientInfoOptions}
            errorMessage={errorMessage}
            // Note, ClientInfo may be the first step.
            onBackClick={isBackButtonEnabled ? handleFirstBackClick : undefined}
            onChange={handleClientInfoChange}
            onNextClick={handleClientInfoStepFinish}
          />
        );
      case 'contracts':
        return (
          <Contracts
            isLoading={isSubmitting}
            onBackClick={showPrevStep}
            onNextClick={showNextStep.bind(null, 'payment')}
            windowObj={windowObj}
          />
        );
      case 'payment':
        return (
          <Payment
            documentObj={documentObj}
            onBackClick={showPrevStep}
            onClose={onClose}
            windowObj={windowObj}
          />
        );
      default:
        return null;
    }
  }

  return <div className="relative flex flex-1 flex-col">{renderStep()}</div>;
};

export { InstantBookingQuestion };
