import React from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { Input } from '@bloom/ui/components/Input';
import { emptyArray } from '@bloom/ui/utils/empty-value';

import { PhoneInput } from '@bloom/library/components/Form/PhoneInputV3';
import {
  useAnswer,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { IClientInfoQuestion } from '@bloom/library/types/questionnaire';
import { ClientInfoFieldEnum } from '@bloom/library/types/templates';
import { isEmail, isPhoneNumber } from '@bloom/library/utils/misc';

const PersonalInfoQuestion: React.FC<{
  autoFocus?: boolean;
  index: number;
  question: IClientInfoQuestion;
}> = (props) => {
  const { index, autoFocus, question } = props;
  const [
    {
      modal: { isValidationOn },
      questionnaire: { settings },
    },
    { setAnswer },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const answer = (useAnswer(index) || emptyArray) as Array<string>;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setAnswer(
      index,
      question.options.map((n, i) => (i === +name ? value : answer[i] || ''))
    );
  }

  return (
    <div
      className={twMerge(
        'grid w-full max-w-3xl gap-3 md:grid-cols-2',
        alignment === QuestionnaireAlignment.RIGHT ? 'ml-auto' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'mr-auto' : '',
        alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : ''
      )}
    >
      {question.options.map((name, i, arr) =>
        name === ClientInfoFieldEnum.PHONE_NUMBER ? (
          <PhoneInput
            autoFocus={autoFocus && i === 0}
            className={twMerge(i == arr.length - 1 && i % 2 === 0 ? 'md:col-span-2' : '')}
            data-testid="phone-input"
            invalid={isValidationOn && !isPhoneNumber(answer[i] || '')}
            key={i}
            label={name}
            name={i.toString()}
            onChange={handleChange}
            placeholder={name}
            value={answer[i] || ''}
          />
        ) : (
          <Input
            autoFocus={autoFocus && i === 0}
            className={twMerge(i == arr.length - 1 && i % 2 === 0 ? 'md:col-span-2' : '')}
            data-testid={`${name.replace(' ', '-').toLowerCase()}-input`}
            invalid={
              isValidationOn &&
              ((name === ClientInfoFieldEnum.EMAIL_ADDRESS && !isEmail(answer[i] || '')) ||
                !answer[i])
            }
            key={i}
            label={name}
            name={i.toString()}
            onChange={handleChange}
            placeholder={name}
            type={name === ClientInfoFieldEnum.EMAIL_ADDRESS ? 'email' : 'text'}
            value={answer[i] || ''}
          />
        )
      )}
    </div>
  );
};

export { PersonalInfoQuestion };
