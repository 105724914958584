import React from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';

interface IProps {
  windowObj?: Window;
}

const NoQuestions: React.FC<IProps> = (props) => {
  const { windowObj } = props;

  const [
    {
      questionnaire: { settings },
    },
    { showView },
  ] = useQuoteRequest();
  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  function handleNextClick() {
    if (settings.isCustomConfirmationEnabled) {
      window.location.href = settings.customConfirmationUrl;
    } else {
      showView(QuoteRequestScreenEnum.OUTRO);
    }
  }

  return (
    <div
      className={twMerge(
        'font-regular m-auto w-full max-w-3xl px-6 py-12 text-center md:py-20',
        alignment === QuestionnaireAlignment.CENTER ? 'text-center' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'text-left' : '',
        alignment === QuestionnaireAlignment.RIGHT ? 'text-right' : ''
      )}
    >
      <QuoteRequestHeadline
        headerProps={{ dangerouslySetInnerHTML: { __html: 'No questions configured' } }}
      />

      <ButtonsContainer onNextClick={handleNextClick} windowObj={windowObj}>
        <QuoteRequestPrimaryButton data-testid="next-button" onClick={handleNextClick}>
          Next
        </QuoteRequestPrimaryButton>
      </ButtonsContainer>
    </div>
  );
};

export { NoQuestions };
