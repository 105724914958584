import { useMemo } from 'react';

import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useFetch } from '@bloom/library/components/hooks/useFetch';

import { SharedQueryKeyEnum } from './interface';

import { IAvailabilitySettings } from '../Availability/types';

export const useAvailabilitySettingsPublic = (
  userId,
  queryConfig: Partial<
    UseQueryOptions<IAvailabilitySettings, unknown, IAvailabilitySettings, Array<string>>
  > = {}
) => {
  const { get } = useFetch();

  async function fetchAvailabilitySettings(): Promise<IAvailabilitySettings> {
    const response = await get<IAvailabilitySettings>(`/api/users/${userId}/availability-settings`);

    if (response.status === AsyncStatusEnum.SUCCESS) {
      return response.data;
    }
    return null;
  }

  const { data: availabilitySettings, isFetching } = useQuery({
    queryFn: fetchAvailabilitySettings,
    queryKey: [SharedQueryKeyEnum.AVAILABILITY_SETTINGS_PUBLIC, userId],
    staleTime: Infinity,
    ...queryConfig,
  });

  return useMemo(() => ({ availabilitySettings, isFetching }), [availabilitySettings, isFetching]);
};
