'use client';
import React, { useCallback, useMemo, useState } from 'react';

import { addMonths, differenceInCalendarMonths, format, parseISO, startOfMonth } from 'date-fns';
import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { DatePicker } from '@bloom/ui/components/DatePicker';
import { emptyObject } from '@bloom/ui/utils/empty-value';

import { AvailabilityDateTimePicker } from '@bloom/library/components/AvailabilityDateTimePicker';
import { useCalendarGridParams } from '@bloom/library/components/Calendar/useCalendarGridParams';
import { useAggregatedAvailabilityExceptions } from '@bloom/library/components/hooks/useAggregatedAvailabilityExceptions';
import { useAvailabilitySettingsPublic } from '@bloom/library/components/hooks/useAvailabilitySettingsPublic';
import Loader from '@bloom/library/components/Loader';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ISchedulingQuestion } from '@bloom/library/types/questionnaire';
import { getMonthOffsets } from '@bloom/library/utils/date';

const DateQuestion: React.FC<{ question: ISchedulingQuestion }> = ({ question }) => {
  const [
    {
      dateTimePicker,
      questionnaire: { owner, settings },
    },
    { selectDate, selectTime, setTimezoneName },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const {
    options: { allDay },
  } = question;

  const { selectedDate, selectedTime, selectedTimezoneName } = dateTimePicker;

  const [monthOffset, setMonthOffset] = useState(0);

  const datePickerDefaultMonth = useMemo(
    () => addMonths(startOfMonth(new Date()), monthOffset),
    [monthOffset]
  );

  const { endDate, startDate } = useCalendarGridParams(
    format(datePickerDefaultMonth, 'yyyy-MM-dd')
  );

  const { availabilityExceptions, isFetching: isAvailabilityExceptionsLoading } =
    useAggregatedAvailabilityExceptions({
      endDate,
      questionId: question.id,
      startDate,
      timezone: selectedTimezoneName,
      userId: owner.id,
    });

  const { availabilitySettings, isFetching: isAvailabilitySettingsLoading } =
    useAvailabilitySettingsPublic(owner.id);

  const handleSelectDate = useCallback(
    (date: string) => {
      if (date !== selectedDate) {
        selectDate(date.slice(0, 10));
        if (date) {
          // if date is null or an empty string (unselected) the getMonthOffsets will return NaN
          setMonthOffset(
            differenceInCalendarMonths(date ? parseISO(date) : new Date(), new Date())
          );
        }
        selectTime('');
      }
    },
    [selectDate, selectTime, selectedDate]
  );

  const handleSelectTime = useCallback(
    (time: string) => {
      if (!time) {
        return;
      }

      selectTime(time);
    },
    [selectTime]
  );

  const handleMonthChange = useCallback((date: Date) => {
    const today = new Date();
    setMonthOffset(getMonthOffsets(today, date));
  }, []);

  const { filterByAvailability = true } = question.options;

  const isDateDisabledFn = useCallback(
    (date: Date) => {
      const { schedule } = availabilityExceptions?.[format(date, 'yyyy-MM-dd')] || {};

      if (schedule && schedule.length === 0) {
        return true;
      }

      return false;
    },
    [availabilityExceptions]
  );

  if (isAvailabilitySettingsLoading || (allDay && isAvailabilityExceptionsLoading)) {
    return (
      <div className="relative p-20">
        <Loader />
      </div>
    );
  }

  if (allDay) {
    return (
      <DatePicker
        classNames={{
          month_grid: 'w-full table-fixed border-collapse mt-6',
          months: 'flex w-full flex-col text-black dark:text-white',
          root: twMerge(
            'w-full max-w-sm',
            alignment === QuestionnaireAlignment.LEFT ? 'mr-auto' : '',
            alignment === QuestionnaireAlignment.RIGHT ? 'ml-auto' : '',
            alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : '',
            isAvailabilityExceptionsLoading ? 'pointer-events-none opacity-0' : ''
          ),
        }}
        data-testid="date-picker"
        defaultMonth={datePickerDefaultMonth}
        disabled={isDateDisabledFn}
        mode="single"
        onMonthChange={handleMonthChange}
        onSelect={handleSelectDate}
        selected={selectedDate ? parseISO(selectedDate) : undefined}
        showOutsideDays={false}
        startMonth={new Date()}
      />
    );
  }

  return (
    <AvailabilityDateTimePicker
      availabilityExceptions={availabilityExceptions || emptyObject}
      availabilitySettings={availabilitySettings}
      className={twMerge(
        alignment === QuestionnaireAlignment.LEFT ? 'ml-0' : '',
        alignment === QuestionnaireAlignment.RIGHT ? 'mr-0' : '',
        alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : ''
      )}
      defaultMonth={datePickerDefaultMonth}
      filterByAvailability={filterByAvailability}
      header="Available times"
      isAvailabilityExceptionsLoading={isAvailabilityExceptionsLoading}
      localeSettings={owner.localeSettings}
      onDateSelect={handleSelectDate}
      onMonthChange={handleMonthChange}
      onTimeSelect={handleSelectTime}
      onTimezoneSelect={setTimezoneName}
      selectedDate={selectedDate.slice(0, 10)}
      selectedTime={selectedTime.replace(':', '')}
      selectedTimezone={selectedTimezoneName}
    />
  );
};

export { DateQuestion };
