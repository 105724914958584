import React, { useMemo } from 'react';

import { PrimaryButton } from '@bloom/ui/components/PrimaryButton';

import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';

const QuoteRequestPrimaryButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof PrimaryButton>
>((props, forwardRef) => {
  const { style: styleProp, ...restProps } = props;

  const [
    {
      questionnaire: { account, settings },
    },
  ] = useQuoteRequest();

  const customColor = useCustomColor(settings?.button?.color || account?.settings?.customColor);

  const style = useMemo(
    () => ({
      ...styleProp,
      borderRadius: `${settings?.button?.borderRadius ?? 4}px`,
      fontWeight: settings?.text?.body?.fontWeight ? 700 : '',
    }),
    [settings?.button?.borderRadius, settings?.text?.body?.fontWeight, styleProp]
  );

  return (
    <PrimaryButton
      color={customColor}
      variant="custom"
      {...restProps}
      ref={forwardRef}
      style={style}
    />
  );
});

export { QuoteRequestPrimaryButton };
