import React from 'react';

import momentTz from 'moment-timezone';

import { AttachmentResponse } from '@bloom/codegen/models/AttachmentResponse';
import { QuestionType } from '@bloom/codegen/models/QuestionType';

import { IconButton } from '@bloom/ui/components/IconButton';
import { AnchorText } from '@bloom/ui/components/Typography/AnchorText';

import { PencilIcon } from '@bloom/library/components/Icon/Pencil';
import { questionTypeIconMap } from '@bloom/library/components/Questionnaire/constants';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import {
  useQuestionnaireSubmit,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';

const Summary: React.FC<{ windowObj?: Window }> = (props) => {
  const { windowObj } = props;

  const [
    {
      dateTimePicker: { selectedTimezoneName },
      modal: { answers },
      questionnaire: { owner, questions },
    },
    { showQuestion, showView },
  ] = useQuoteRequest();

  const { pending, submitQuestionnaireForm } = useQuestionnaireSubmit();

  function handleBackClick() {
    showView(QuoteRequestScreenEnum.QUESTION);
  }

  return (
    <div
      className="font-regular mx-auto flex w-full max-w-3xl flex-col px-6 py-12 md:py-20"
      data-testid="question-summary"
    >
      <QuoteRequestHeadline
        className="mb-6"
        headerProps={{ dangerouslySetInnerHTML: { __html: 'Form Summary' } }}
      />

      <section className="divide-black-5 dark:divide-white-5 divide-y">
        {questions.map((question, index) => {
          const answer = answers[index];

          const Icon = questionTypeIconMap[question.type];

          if (question.type === QuestionType.CONTENT) {
            return null;
          }

          return (
            <div
              className="hover:bg-black-5 hover:dark:bg-white-5 group flex items-center justify-between gap-3 py-3"
              key={question.id}
            >
              <div className="flex basis-1/3 items-center gap-3">
                <div className="bg-light-grey text-grey inline-flex rounded-full p-2">
                  <Icon width={16} />
                </div>
                {question.title}
              </div>
              <div className="flex items-center gap-2 whitespace-pre text-right font-medium">
                <div
                  className="whitespace-pre-wrap"
                  data-testid={`${question.type.toLowerCase()}-answer`}
                >
                  {question.type === QuestionType.ATTACHMENT ? (
                    typeof answer === 'undefined' ? (
                      'N/A'
                    ) : (
                      <ul className="flex flex-col">
                        {React.Children.toArray(
                          (answer as unknown as Array<AttachmentResponse>).map((file) => (
                            <li>
                              <AnchorText href={file.url} target="_blank">
                                {file.title}
                              </AnchorText>
                            </li>
                          ))
                        )}
                      </ul>
                    )
                  ) : null}
                  {question.type === QuestionType.PERSONAL_INFO ? (
                    <>
                      {React.Children.toArray(
                        (answer as unknown as Array<string>).map((a) => (
                          <>
                            {a}
                            <br />
                          </>
                        ))
                      )}
                    </>
                  ) : null}
                  {question.type === QuestionType.MULTIPLE_CHOICE ? (
                    <>
                      {typeof answer === 'string' ? answer : null}
                      {Array.isArray(answer)
                        ? React.Children.toArray(
                            answer.map((a) => (
                              <>
                                {a}
                                <br />
                              </>
                            ))
                          )
                        : null}
                    </>
                  ) : null}
                  {question.type === QuestionType.BOOLEAN ? (answer ? 'Yes' : 'No') : null}
                  {question.type === QuestionType.DATE
                    ? momentTz(answer as unknown as string)
                        .tz(selectedTimezoneName)
                        .format(
                          `MMM DD, YYYY ${
                            'allDay' in question.options && question.options.allDay
                              ? ''
                              : owner.localeSettings.timeFormat
                          } (z)`
                        )
                    : null}
                  {[
                    QuestionType.ADDRESS,
                    QuestionType.SPECIALTY,
                    QuestionType.ONE_LINE_TEXT,
                    QuestionType.TEXTUAL,
                  ].includes(question.type) ? (
                    <>{answer}</>
                  ) : null}
                </div>
                <div className="hidden group-hover:block">
                  <IconButton
                    data-testid="edit-question-button"
                    item={index}
                    onClick={showQuestion}
                  >
                    <PencilIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          );
        })}
      </section>

      <ButtonsContainer onNextClick={submitQuestionnaireForm} windowObj={windowObj}>
        <QuoteRequestPrimaryButton
          data-testid="back-button"
          onClick={handleBackClick}
          variant="secondary"
        >
          Back
        </QuoteRequestPrimaryButton>

        <QuoteRequestPrimaryButton
          data-testid="submit-button"
          loading={pending}
          onClick={submitQuestionnaireForm}
        >
          Submit
        </QuoteRequestPrimaryButton>
      </ButtonsContainer>
    </div>
  );
};

export { Summary };
