import React, { useCallback, useEffect } from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';

interface IProps {
  children: React.ReactNode;
  className?: string;
  onNextClick?: () => void;
  windowObj?: Window;
}

export const ButtonsContainer: React.FC<IProps> = (props) => {
  const { children, className = '', onNextClick, windowObj = window } = props;

  const [
    {
      questionnaire: { settings },
    },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey && typeof onNextClick === 'function') {
        // Prevent default behavior of the Enter key
        // so it does not add a new line in the text field
        // before submitting the form
        e.preventDefault();
        onNextClick();
      }
    },
    [onNextClick]
  );

  useEffect(() => {
    const ac = new AbortController();
    windowObj.addEventListener('keydown', handleKeyDown, { signal: ac.signal });

    return () => {
      ac.abort();
    };
  }, [children, handleKeyDown, windowObj]);

  return (
    <div
      className={twMerge(
        'mt-12 flex',
        alignment === QuestionnaireAlignment.CENTER ? 'justify-center' : '',
        alignment === QuestionnaireAlignment.RIGHT ? 'justify-end' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'justify-start' : '',
        className
      )}
    >
      <div className="relative inline-flex items-center gap-3">{children}</div>
    </div>
  );
};
