import { OrderItemResponse } from '@bloom/codegen/models/OrderItemResponse';

import { Checkbox } from '@bloom/ui/components/Checkbox';
import { SelectBox } from '@bloom/ui/components/SelectBox';
import { doNothing } from '@bloom/ui/utils/empty-value';
import { stopPropagation } from '@bloom/ui/utils/functions';

import { MinusIcon } from '@bloom/library/components/Icon/Minus';
import { PlusIcon } from '@bloom/library/components/Icon/Plus';
import { H1 } from '@bloom/library/components/Typography/H1';
import { H2 } from '@bloom/library/components/Typography/H2';
import { escapeHTML, formatMoney } from '@bloom/library/utils/string';

import { useQuoteRequest } from '../../quote-request-context';

interface IProps {
  addon: OrderItemResponse;
  'data-testid': string;
}

const Addon: React.FC<IProps> = (props) => {
  const { addon, 'data-testid': dataTestId } = props;

  const [
    {
      instantBooking,
      questionnaire: { owner },
    },
    { toggleAddon, updateInstantBookingAddon },
  ] = useQuoteRequest();
  const { addons } = instantBooking;

  const selectedAddonState = addons.find((a) => a.slug === addon.displayName);
  const quantity = selectedAddonState ? Number(selectedAddonState.quantity) : 0;

  function handleClick() {
    toggleAddon({ ...addon, ...selectedAddonState, quantity: 1, slug: addon.displayName });
  }

  function incrementQuantity() {
    if (selectedAddonState) {
      updateInstantBookingAddon({
        ...addon,
        ...selectedAddonState,
        quantity: Number(selectedAddonState?.quantity) + 1,
      });
    } else {
      handleClick();
    }
  }

  function decrementQuantity() {
    const newQuantity = Math.max(0, Number(selectedAddonState?.quantity) - 1);

    updateInstantBookingAddon({ ...addon, ...selectedAddonState, quantity: newQuantity });

    if (selectedAddonState && newQuantity === 0) {
      handleClick();
    }
  }

  const currencyCode = owner.currencyCode || 'USD';

  return (
    <SelectBox
      className="flex-1 !p-6"
      data-testid={dataTestId}
      name="addon"
      onClick={handleClick}
      selected={!!quantity}
      style={{ maxWidth: 380, minHeight: '100px', minWidth: 280 }}
      value=""
    >
      <header className="flex cursor-pointer gap-3 text-black dark:text-white">
        <Checkbox
          checked={!!quantity}
          className="mt-0.5"
          data-testid={`${dataTestId}-checkbox`}
          label={
            <H2
              className="min-w-0 truncate"
              dangerouslySetInnerHTML={{ __html: escapeHTML(addon.displayName) }}
              title={addon.displayName}
            />
          }
          onChange={doNothing}
        />
      </header>

      <div className="mt-3 text-black dark:text-white">
        {addon.description
          .split(/\n/)
          .map((paragraph: string, index: number) =>
            paragraph ? (
              <p dangerouslySetInnerHTML={{ __html: escapeHTML(paragraph) }} key={index} />
            ) : (
              <br key={index} />
            )
          )}
      </div>

      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <footer
        className="mt-6 flex items-center justify-between text-black dark:text-white"
        onClick={stopPropagation}
      >
        <div className="hover:border-black-15 dark:border-white-15 inline-flex items-center gap-2 rounded-sm border border-transparent p-2">
          <button
            className="text-black-50 dark:text-white-50 h-5 w-5 cursor-pointer hover:text-black dark:hover:text-white"
            data-testid="decrement-button"
            onClick={decrementQuantity}
            type="button"
          >
            <MinusIcon />
          </button>
          <span className="inline-block w-8 text-center" data-testid={`${dataTestId}-quantity`}>
            {quantity}
          </span>
          <button
            className="text-black-50 dark:text-white-50 h-5 w-5 cursor-pointer hover:text-black dark:hover:text-white"
            data-testid="increment-button"
            onClick={incrementQuantity}
            type="button"
          >
            <PlusIcon />
          </button>
        </div>

        <H1 as="div">
          {formatMoney((Number(addon.unitPrice) || 0) * Number(addon.quantity), currencyCode)}
        </H1>
      </footer>
    </SelectBox>
  );
};

export { Addon };
