'use client';
import React, { useEffect, useMemo, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireColorTheme } from '@bloom/codegen/models/QuestionnaireColorTheme';
import { QuestionnaireType } from '@bloom/codegen/models/QuestionnaireType';

import { PoweredBy } from '@bloom/ui/components/PoweredBy';
import { standardColors } from '@bloom/ui/constants/colors';
import { emptyArray } from '@bloom/ui/utils/empty-value';

import { usePublicAccount } from '@bloom/library/components/hooks/usePublicAccount';
import { usePublicCustomFonts } from '@bloom/library/components/hooks/usePublicCustomFonts';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import { QuoteRequestHeader } from '@bloom/library/components/QuoteRequest/QuoteRequestHeader';
import { QuoteRequestProgress } from '@bloom/library/components/QuoteRequest/QuoteRequestProgress';
import { addFontSource } from '@bloom/library/utils/customizations';

import { MultiStepForm } from './MultiStepForm';
import { SingleStepForm } from './SingleStepForm';

interface IProps {
  documentObj?: Document;
  isEmbeddedDirectly?: boolean;
  onClose: () => void;
  windowObj?: Window;
}

const QuoteRequest: React.FC<IProps> = (props) => {
  const { documentObj = document, isEmbeddedDirectly = false, onClose, windowObj = window } = props;

  const [{ instantBooking, isPreview, modal, questionnaire }, { setTimezoneName }] =
    useQuoteRequest();

  const { step } = instantBooking;
  const stepRef = useRef(step);

  const { questionIndex } = modal;
  const questionIndexRef = useRef(questionIndex);

  const { owner, settings } = questionnaire;

  const { account: publicAccount, isFetching } = usePublicAccount(questionnaire?.accountId);

  const ref = useRef<HTMLDivElement>(null);

  const { data: customFonts = emptyArray } = usePublicCustomFonts(owner.id);

  useEffect(() => {
    if (ref.current && settings) {
      if (settings.text?.header.fontFamily) {
        addFontSource({
          customFonts,
          doc: documentObj,
          fontName: settings.text.header.fontFamily,
          source: settings.text.header.source,
        });
        ref.current.style.setProperty('--font-accent', settings.text.header.fontFamily);
      } else {
        ref.current.style.removeProperty('--font-accent');
      }

      if (settings.text?.body.fontFamily) {
        const { fontFamily } = settings.text.body;

        ref.current.style.setProperty('--font-regular', fontFamily);
        ref.current.style.setProperty('--font-medium', fontFamily);

        addFontSource({
          customFonts,
          doc: documentObj,
          fontName: settings.text.body.fontFamily,
          source: settings.text.body.source,
        });
      } else {
        ref.current.style.removeProperty('--font-regular');
        ref.current.style.removeProperty('--font-medium');
      }

      if (settings.text?.body.fontSize) {
        const smRem = +settings.text?.body.fontSize / 16;
        const lgRem = smRem * (18 / 14);

        ref.current.style.setProperty('--font-size-sm', `${smRem}rem`);
        ref.current.style.setProperty('--font-size-lg', `${lgRem}rem`);

        ref.current.style.setProperty('--line-height-sm', `${smRem * (20 / 14)}rem`);
        ref.current.style.setProperty('--line-height-lg', `${lgRem * (24 / 18)}rem`);
      } else {
        ref.current.style.removeProperty('--font-size-sm');
        ref.current.style.removeProperty('--font-size-lg');
        ref.current.style.removeProperty('--line-height-sm');
        ref.current.style.removeProperty('--line-height-lg');
      }

      if (settings.colorTheme === QuestionnaireColorTheme.DARK) {
        ref.current.style.setProperty('--color-input-appearance', standardColors.black);
      } else {
        ref.current.style.setProperty('--color-input-appearance', standardColors.white);
      }
    }
  }, [customFonts, documentObj, settings]);

  useEffect(() => {
    const selectedTimezoneName =
      publicAccount?.settings.timezone?.name ||
      (window.Intl && Intl.DateTimeFormat().resolvedOptions().timeZone)
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : '';

    setTimezoneName(selectedTimezoneName);
  }, [publicAccount?.settings.timezone?.name, setTimezoneName]);

  useEffect(() => {
    if (ref.current && (stepRef.current !== step || questionIndexRef.current !== questionIndex)) {
      ref.current.scrollIntoView();
    }
    stepRef.current = step;
    questionIndexRef.current = questionIndex;
  }, [step, questionIndex, windowObj]);

  const isMultiStep = useMemo(() => {
    if (questionnaire.type === QuestionnaireType.INSTANT_BOOK) {
      return true;
    }
    return settings.isMultiStep;
  }, [questionnaire.type, settings.isMultiStep]);

  return (
    <div
      data-mode={settings?.colorTheme === QuestionnaireColorTheme.DARK ? 'dark' : ''}
      ref={ref}
      style={{ backgroundColor: settings?.backgroundColor }}
    >
      <div
        className={twMerge(
          'flex flex-col text-black dark:text-white',
          isEmbeddedDirectly ? 'min-h-0' : '',
          !isEmbeddedDirectly && !isPreview ? 'min-h-screen' : ''
        )}
      >
        {isEmbeddedDirectly ? null : (
          <QuoteRequestHeader>
            <QuoteRequestProgress />
          </QuoteRequestHeader>
        )}

        {isMultiStep ? (
          <MultiStepForm
            documentObj={documentObj}
            isEmbeddedDirectly={isEmbeddedDirectly}
            onClose={onClose}
            windowObj={windowObj}
          />
        ) : (
          <SingleStepForm documentObj={documentObj} onClose={onClose} windowObj={windowObj} />
        )}

        {isFetching || publicAccount?.isBrandingRemoved ? null : (
          <PoweredBy
            className="text-black-50 dark:text-white-50 mx-auto mb-8 mt-auto text-xs"
            isBrandingRemoved={false}
            logoWidth={36}
            utm_medium="forms"
          />
        )}
      </div>
    </div>
  );
};

export default QuoteRequest;
