import React, { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireType } from '@bloom/codegen/models/QuestionnaireType';
import { QuestionType } from '@bloom/codegen/models/QuestionType';

import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';

import { useQuestions, useQuoteRequest, useSelectedPackage } from './quote-request-context';

const QuoteRequestProgress: React.FC = () => {
  const [{ instantBooking, modal: quoteRequestModal, questionnaire }] = useQuoteRequest();
  const { settings } = questionnaire;

  const questions = useQuestions();

  const { currentView, questionIndex } = quoteRequestModal;

  const isMultiStep = useMemo(() => {
    if (questionnaire.type === QuestionnaireType.INSTANT_BOOK) {
      return true;
    }
    return settings.isMultiStep;
  }, [questionnaire.type, settings.isMultiStep]);

  let stepCount = useMemo(() => {
    return questions.filter((q) => (isMultiStep ? true : q.type !== QuestionType.CONTENT)).length;
  }, [questions, isMultiStep]);

  const instantBookingIndex = questions.findIndex((q) => q.type === QuestionType.BOOKING_PACKAGE);

  const hasInstantBookingQuestion = instantBookingIndex > -1;
  const selectedPackage = useSelectedPackage();

  if (hasInstantBookingQuestion) {
    // Note, Instant Booking contains 3 required steps,
    // one of them is counted as the question itself.
    stepCount += 2;

    if (selectedPackage) {
      if (!selectedPackage.bookWithoutPayment) {
        stepCount += 1;
      }
      if (selectedPackage.package.addons.length > 0) {
        stepCount += 1;
      }
      if (selectedPackage.contracts?.length > 0) {
        stepCount += selectedPackage.contracts.length;
      }
    }
  }

  let step = questionIndex;
  if ([QuoteRequestScreenEnum.OUTRO, QuoteRequestScreenEnum.SUMMARY].includes(currentView)) {
    step = stepCount;
  } else if (hasInstantBookingQuestion && questionIndex >= instantBookingIndex) {
    step += instantBooking.stepHistory.length;
  }

  return (
    <div
      className={twMerge(
        'font-regular text-black-50 dark:text-white-50 ml-auto mr-8 hidden text-center sm:inline-block sm:text-right md:mr-6 lg:mr-0',
        isMultiStep ? '' : 'italic'
      )}
    >
      {step !== stepCount || currentView === QuoteRequestScreenEnum.SUMMARY
        ? isMultiStep
          ? `${step} of ${stepCount} answered`
          : `* Required to submit`
        : `You're all done!`}
    </div>
  );
};

export { QuoteRequestProgress };
