import React, { useMemo, useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { Text } from '@bloom/ui/components/Typography/Text';

import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import { HMega } from '@bloom/library/components/Typography/HMega';

interface IProps extends React.ComponentProps<typeof HMega> {
  className?: string;
  descriptionProps?: React.ComponentProps<typeof Text>;
  headerProps: React.ComponentProps<typeof HMega>;
}

const QuoteRequestHeadline: React.FC<IProps> = (props) => {
  const { className, descriptionProps = {}, headerProps, id } = props;
  const computedId = id || 'quote-request-headline';

  const headerRef = useRef<HTMLDivElement>(null);

  const [
    {
      questionnaire: { settings },
    },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const style: React.CSSProperties = useMemo(
    () => ({
      ...headerProps.style,
      color: settings?.text?.header.color || '',
      fontFamily: settings?.text?.header.fontFamily || '',
      fontSize:
        (settings?.text?.header.fontSize
          ? `${Number(settings.text?.header.fontSize) / 16}rem`
          : '') || '1.5rem',
      fontWeight: settings?.text?.header.fontWeight || '',
      lineHeight: settings?.text?.header.fontSize ? 1 : undefined,
      textAlign: alignment,
    }),
    [settings.text?.header, alignment, headerProps.style]
  );

  return (
    <hgroup
      className={twMerge(
        headerProps.dangerouslySetInnerHTML?.__html ||
          descriptionProps.dangerouslySetInnerHTML?.__html
          ? 'mb-12'
          : '',
        className
      )}
      id={computedId}
      ref={headerRef}
    >
      {headerProps.dangerouslySetInnerHTML?.__html ? (
        <HMega className="break-words" {...headerProps} style={style} />
      ) : null}
      {descriptionProps.dangerouslySetInnerHTML?.__html ? (
        <Text
          {...descriptionProps}
          className={twMerge('font-regular mt-2 whitespace-pre-wrap', descriptionProps.className)}
          style={{
            color: settings?.text?.body.color || '',
            ...descriptionProps.style,
            textAlign: alignment,
          }}
        />
      ) : null}
    </hgroup>
  );
};

export { QuoteRequestHeadline };
