import React from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { SelectBox } from '@bloom/ui/components/SelectBox';
import { Text, TextVariantEnum } from '@bloom/ui/components/Typography/Text';

import {
  useAnswer,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';

const BooleanQuestion: React.FC<{ index: number }> = (props) => {
  const { index } = props;
  const [
    {
      questionnaire: { settings },
    },
    { setAnswer },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const answer = useAnswer(index) as boolean;

  function handleChange(_, value: boolean) {
    setAnswer(index, value);
  }

  return (
    <div
      className={twMerge(
        'flex flex-col items-center gap-3 sm:flex-row',
        alignment === QuestionnaireAlignment.CENTER ? 'justify-center' : '',
        alignment === QuestionnaireAlignment.RIGHT ? 'justify-end' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'justify-start' : ''
      )}
    >
      <SelectBox
        className="w-32"
        data-testid="no-option"
        name=""
        onClick={handleChange}
        selected={answer === false}
        value={false}
      >
        <Text className="text-center text-black dark:text-white" variant={TextVariantEnum.MEDIUM}>
          No
        </Text>
      </SelectBox>

      <SelectBox
        className="w-32"
        data-testid="yes-option"
        name=""
        onClick={handleChange}
        selected={answer === true}
        value={true}
      >
        <Text className="text-center text-black dark:text-white" variant={TextVariantEnum.MEDIUM}>
          Yes
        </Text>
      </SelectBox>
    </div>
  );
};

export { BooleanQuestion };
