'use client';

import { twMerge } from 'tailwind-merge';

import { QuestionType } from '@bloom/codegen/models/QuestionType';

import Loader from '@bloom/library/components/Loader';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import {
  useQuestions,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ConfirmClose } from '@bloom/library/components/QuoteRequest/views/ConfirmClose';
import { InstantBookingQuestion } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion';
import Question from '@bloom/library/components/QuoteRequest/views/Question';
import { Intro } from '@bloom/library/components/QuoteRequest/views/Questions/Intro';
import { NoQuestions } from '@bloom/library/components/QuoteRequest/views/Questions/NoQuestions';
import { Outro } from '@bloom/library/components/QuoteRequest/views/Questions/Outro';
import { Summary } from '@bloom/library/components/QuoteRequest/views/Questions/Summary';

interface IProps {
  documentObj?: Document;
  isEmbeddedDirectly?: boolean;
  onClose: () => void;
  windowObj?: Window;
}

const MultiStepForm: React.FC<IProps> = (props) => {
  const { documentObj, isEmbeddedDirectly = false, onClose, windowObj } = props;
  const [{ isPreview, modal, questionnaire }] = useQuoteRequest();

  const { currentView, questionIndex } = modal;

  const questions = useQuestions();

  const question = questions[questionIndex];

  if (currentView === QuoteRequestScreenEnum.QUESTION && !questionnaire.id && !isPreview) {
    return (
      <div className={twMerge('relative flex flex-1 flex-col', isEmbeddedDirectly ? 'py-20' : '')}>
        <Loader />
      </div>
    );
  }

  if (currentView === QuoteRequestScreenEnum.INTRO) {
    return (
      <div className="font-regular container max-w-3xl py-12 md:py-20">
        <Intro windowObj={windowObj} />
      </div>
    );
  }

  if (currentView === QuoteRequestScreenEnum.OUTRO) {
    return <Outro onClose={onClose} windowObj={windowObj} />;
  }

  if (currentView === QuoteRequestScreenEnum.SUMMARY) {
    return <Summary windowObj={windowObj} />;
  }

  if (currentView === QuoteRequestScreenEnum.CONFIRM_CLOSE) {
    return <ConfirmClose onClose={onClose} />;
  }

  if (currentView === QuoteRequestScreenEnum.QUESTION) {
    if (question) {
      if (question.type === QuestionType.BOOKING_PACKAGE) {
        return (
          <InstantBookingQuestion
            documentObj={documentObj}
            onClose={onClose}
            windowObj={windowObj}
          />
        );
      }

      return (
        <Question autoFocus={!isEmbeddedDirectly} index={questionIndex} windowObj={windowObj} />
      );
    }

    return <NoQuestions />;
  }

  return null;
};

export { MultiStepForm };
