import React from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { Input } from '@bloom/ui/components/Input';

import {
  useAnswer,
  useQuoteRequest,
} from '@bloom/library/components/QuoteRequest/quote-request-context';

const OneLineQuestion: React.FC<{ autoFocus?: boolean; index: number }> = (props) => {
  const { index, autoFocus } = props;
  const [
    {
      questionnaire: { settings },
    },
    { setAnswer },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  // ! Make sure the answer is always a string (the textarea is controlled)
  // If the form contains multiple same type questions
  // React optimizing the rendering of the component persists the
  // value of the input field from previous question in case if the new value is undefined.
  // Undefined converts the input field to uncontrolled.
  const answer = useAnswer<string | undefined>(index) || '';

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setAnswer(index, e.target.value);
  }

  return (
    <Input
      autoFocus={autoFocus}
      className={twMerge(
        'w-full max-w-3xl',
        alignment === QuestionnaireAlignment.RIGHT ? 'ml-auto' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'mr-auto' : '',
        alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : ''
      )}
      data-testid="answer-input"
      onChange={handleChange}
      placeholder="Enter your answer"
      value={answer}
    />
  );
};

export { OneLineQuestion };
