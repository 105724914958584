// eslint-disable-next-line
export function getProjectDuration(project: any): string {
  if (project.duration) {
    return project.duration;
  }

  // Try to infer project duration from items.
  let minutes = 0;
  project.package.items.forEach((item) => {
    if (item.duration) {
      const [h, m] = item.duration.split(':');
      minutes += (60 * Number(h) + Number(m)) * Number(item.quantity || 1);
    }
  });
  if (minutes) {
    return `${Math.floor(minutes / 60)
      .toString()
      .padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}`;
  }

  return '';
}
