import React, { useCallback, useMemo, useState } from 'react';

import { parseISO } from 'date-fns';
import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { DatePicker } from '@bloom/ui/components/DatePicker';
import { emptyObject } from '@bloom/ui/utils/empty-value';

import { AvailabilityDateTimePicker } from '@bloom/library/components/AvailabilityDateTimePicker';
import { useCalendarGridParams } from '@bloom/library/components/Calendar/useCalendarGridParams';
import { useAggregatedAvailabilityExceptions } from '@bloom/library/components/hooks/useAggregatedAvailabilityExceptions';
import { useAvailabilitySettingsPublic } from '@bloom/library/components/hooks/useAvailabilitySettingsPublic';
import { useMoment } from '@bloom/library/components/hooks/useMoment';
import { ArrowIcon } from '@bloom/library/components/Icon/Arrow';
import Loader from '@bloom/library/components/Loader';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import {
  useCurrentQuestion,
  useQuoteRequest,
  useSelectedPackage,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';
import { IBookingPackageQuestion } from '@bloom/library/types/questionnaire';
import { getMonthOffsets } from '@bloom/library/utils/date';
import { getProjectDuration } from '@bloom/library/utils/project';
import { escapeHTML } from '@bloom/library/utils/string';
import { convertToMinutes } from '@bloom/library/utils/time';

interface IProps {
  onBackClick: () => void;
  onNextClick: () => void;
  windowObj?: Window;
}

const DateStep: React.FC<IProps> = (props) => {
  const { onBackClick, onNextClick, windowObj } = props;

  const question = useCurrentQuestion() as IBookingPackageQuestion;
  const {
    options: { allDay },
  } = question;

  const [monthOffset, setMonthOffset] = useState(0);

  const { moment, momentTz } = useMoment();

  const [
    {
      dateTimePicker,
      instantBooking,
      questionnaire: { owner, settings },
    },
    { selectDate, selectTime, setTimezoneName },
  ] = useQuoteRequest();

  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const { selectedDate, selectedTime, selectedTimezoneName } = dateTimePicker;

  const { addons: selectedAddons } = instantBooking;

  const datePickerDefaultMonth = useMemo(() => {
    return momentTz().startOf('month').add(monthOffset, 'month');
  }, [momentTz, monthOffset]);

  const { endDate, startDate } = useCalendarGridParams(datePickerDefaultMonth);

  const { availabilityExceptions, isFetching: isAvailabilityExceptionsLoading } =
    useAggregatedAvailabilityExceptions({
      endDate,
      questionId: question.id,
      startDate,
      timezone: selectedTimezoneName,
      userId: owner.id,
    });

  const { availabilitySettings, isFetching: isAvailabilitySettingsLoading } =
    useAvailabilitySettingsPublic(owner.id);

  const handleSelectDate = useCallback(
    (date: string) => {
      if (date !== selectedDate) {
        selectDate(date);

        if (date) {
          // if date is null or an empty string (unselected) the getMonthOffsets will return NaN
          setMonthOffset(getMonthOffsets(momentTz().toDate(), momentTz(date).toDate()));
        }

        // Reset time if it's set.
        if (selectedTime) {
          selectTime('');
        }
      }
    },
    [momentTz, selectDate, selectTime, selectedDate, selectedTime]
  );

  const handleSelectTime = useCallback(
    (time: string) => {
      if (!time) {
        return;
      }

      selectTime(time);
    },
    [selectTime]
  );

  function handleNextClick() {
    if ((allDay && selectedDate) || (!allDay && selectedDate && selectedTime)) {
      onNextClick();
    }
  }

  const selectedPackage = useSelectedPackage();

  let totalDuration = convertToMinutes(getProjectDuration(selectedPackage));
  selectedAddons.forEach(({ duration, quantity }) => {
    if (duration) {
      totalDuration += convertToMinutes(duration) * Number(quantity);
    }
  });

  const handleMonthChange = useCallback((date: Date) => {
    // selectDate('');
    // selectTime('');

    const today = new Date();
    setMonthOffset(getMonthOffsets(today, date));
  }, []);

  const isDateDisabledFn = useCallback(
    (date: Date) => {
      const { schedule } = availabilityExceptions?.[moment(date).format('YYYY-MM-DD')] || {};

      if (schedule && schedule.length === 0) {
        return true;
      }

      return false;
    },
    [availabilityExceptions, moment]
  );

  return (
    <div className="font-regular container py-12 md:py-20" data-testid="instant-booking-scheduling">
      <QuoteRequestHeadline
        descriptionProps={{
          dangerouslySetInnerHTML: { __html: escapeHTML(question.options.schedulingDescription) },
        }}
        headerProps={{
          dangerouslySetInnerHTML: {
            __html: escapeHTML(question.options.schedulingTitle || 'Please select a day and time'),
          },
        }}
      />

      {isAvailabilitySettingsLoading || (allDay && isAvailabilityExceptionsLoading) ? (
        <div className="relative p-20">
          <Loader />
        </div>
      ) : allDay ? (
        <DatePicker
          classNames={{
            month_grid: 'w-full table-fixed border-collapse mt-6',
            months: 'flex w-full flex-col text-black dark:text-white',
            root: twMerge(
              'w-full max-w-sm',
              alignment === QuestionnaireAlignment.LEFT ? 'mr-auto' : '',
              alignment === QuestionnaireAlignment.RIGHT ? 'ml-auto' : '',
              alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : '',
              isAvailabilityExceptionsLoading ? 'pointer-events-none opacity-0' : ''
            ),
          }}
          data-testid="date-picker"
          defaultMonth={datePickerDefaultMonth.toDate()}
          disabled={isDateDisabledFn}
          mode="single"
          onMonthChange={handleMonthChange}
          onSelect={handleSelectDate}
          selected={selectedDate ? parseISO(selectedDate) : undefined}
          showOutsideDays={false}
          startMonth={new Date()}
        />
      ) : availabilitySettings ? (
        <AvailabilityDateTimePicker
          availabilityExceptions={availabilityExceptions || emptyObject}
          availabilitySettings={availabilitySettings}
          bookingDuration={totalDuration}
          className={twMerge(
            alignment === QuestionnaireAlignment.LEFT ? 'ml-0' : '',
            alignment === QuestionnaireAlignment.RIGHT ? 'mr-0' : '',
            alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : ''
          )}
          defaultMonth={datePickerDefaultMonth.toDate()}
          filterByAvailability={question.options.filterByAvailability}
          isAvailabilityExceptionsLoading={isAvailabilityExceptionsLoading}
          localeSettings={owner.localeSettings}
          onDateSelect={handleSelectDate}
          onMonthChange={handleMonthChange}
          onTimeSelect={handleSelectTime}
          onTimezoneSelect={setTimezoneName}
          placeholder="Please select a date"
          selectedDate={selectedDate.slice(0, 10)}
          selectedTime={selectedTime.replace(':', '')}
          selectedTimezone={selectedTimezoneName}
        />
      ) : null}

      <ButtonsContainer onNextClick={handleNextClick} windowObj={windowObj}>
        <QuoteRequestPrimaryButton
          data-testid="back-button"
          onClick={onBackClick}
          variant="secondary"
        >
          Back
        </QuoteRequestPrimaryButton>
        <QuoteRequestPrimaryButton
          data-testid="next-button"
          disabled={allDay ? !selectedDate : !selectedDate || !selectedTime}
          onClick={handleNextClick}
        >
          Next <ArrowIcon />
        </QuoteRequestPrimaryButton>
      </ButtonsContainer>
    </div>
  );
};

export { DateStep };
