import React from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';
import { QuestionType } from '@bloom/codegen/models/QuestionType';

import { Input } from '@bloom/ui/components/Input';

import { PhoneInput } from '@bloom/library/components/Form/PhoneInputV3';
import { ArrowIcon } from '@bloom/library/components/Icon/Arrow';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import { useQuoteRequest } from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';
import { IClientInfoQuestion } from '@bloom/library/types/questionnaire';
import { ClientInfoFieldEnum } from '@bloom/library/types/templates';
import { isEmail } from '@bloom/library/utils/misc';
import { escapeHTML } from '@bloom/library/utils/string';

interface IProps {
  answer: { [fieldName: string]: string };
  defaultOptions: Array<string>;
  errorMessage?: string;
  onBackClick?: () => void;
  onChange: (name: string, value: string) => void;
  onNextClick: () => void;
  windowObj?: Window;
}

export const ClientInfo: React.FC<IProps> = (props) => {
  const { answer, defaultOptions, errorMessage, onBackClick, onChange, onNextClick, windowObj } =
    props;

  const [{ questionnaire }] = useQuoteRequest();
  const { settings } = questionnaire;
  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  const clientInfoQuestion = questionnaire.questions.find(
    (q) => q.type === QuestionType.PERSONAL_INFO
  ) as IClientInfoQuestion;

  const isValidationOn = !!errorMessage;
  const options = clientInfoQuestion ? clientInfoQuestion.options : defaultOptions;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;

    onChange(name, value);
  }

  return (
    <div
      className={twMerge(
        'font-regular w-full max-w-3xl px-6 py-12 md:py-20',
        alignment === QuestionnaireAlignment.RIGHT ? 'ml-auto' : '',
        alignment === QuestionnaireAlignment.LEFT ? 'mr-auto' : '',
        alignment === QuestionnaireAlignment.CENTER ? 'mx-auto' : ''
      )}
      data-testid="instant-booking-client-info"
    >
      <QuoteRequestHeadline
        descriptionProps={{
          dangerouslySetInnerHTML: {
            __html: escapeHTML(clientInfoQuestion ? clientInfoQuestion.description : ''),
          },
        }}
        headerProps={{
          dangerouslySetInnerHTML: {
            __html: escapeHTML(
              clientInfoQuestion ? clientInfoQuestion.title : 'Please, introduce yourself'
            ),
          },
        }}
      />

      <div className="mx-auto grid gap-6 md:grid-cols-2">
        {options.map((name, i, arr) =>
          name === ClientInfoFieldEnum.PHONE_NUMBER ? (
            <PhoneInput
              className={twMerge(i == arr.length - 1 && i % 2 === 0 ? 'md:col-span-2' : '')}
              data-testid="phone-input"
              invalid={isValidationOn && !answer[name]}
              key={i}
              label={name}
              name={name}
              onChange={handleChange}
              placeholder={name}
              value={answer[name] || ''}
            />
          ) : (
            <Input
              className={twMerge(i == arr.length - 1 && i % 2 === 0 ? 'md:col-span-2' : '')}
              data-testid={`${name.replace(' ', '-').toLowerCase()}-input`}
              invalid={
                isValidationOn &&
                ((name === ClientInfoFieldEnum.EMAIL_ADDRESS && !isEmail(answer[name] || '')) ||
                  !answer[name])
              }
              key={i}
              label={name}
              name={name}
              onChange={handleChange}
              placeholder={name}
              type={name === ClientInfoFieldEnum.EMAIL_ADDRESS ? 'email' : 'text'}
              value={answer[name] || ''}
            />
          )
        )}
      </div>

      <ButtonsContainer onNextClick={onNextClick} windowObj={windowObj}>
        {typeof onBackClick === 'function' ? (
          <QuoteRequestPrimaryButton
            data-testid="back-button"
            onClick={onBackClick}
            variant="secondary"
          >
            Back
          </QuoteRequestPrimaryButton>
        ) : null}
        <QuoteRequestPrimaryButton data-testid="next-button" onClick={onNextClick}>
          Next <ArrowIcon />
        </QuoteRequestPrimaryButton>
      </ButtonsContainer>
    </div>
  );
};
