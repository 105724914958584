import React from 'react';

import { DiscountItemResponse } from '@bloom/codegen/models/DiscountItemResponse';

import { Checkbox } from '@bloom/ui/components/Checkbox';
import { CheckIcon } from '@bloom/ui/components/Icons/Check';
import { SelectBox } from '@bloom/ui/components/SelectBox';
import { Tooltip } from '@bloom/ui/components/Tooltip';
import { Text, TextSizeEnum } from '@bloom/ui/components/Typography/Text';
import { doNothing } from '@bloom/ui/utils/empty-value';

import { useImageFallback } from '@bloom/library/components/hooks/useImageFallback';
import { H1 } from '@bloom/library/components/Typography/H1';
import { H2 } from '@bloom/library/components/Typography/H2';
import { IPackage } from '@bloom/library/types/package';
import { getInstantBookingPackagePaymentDetails } from '@bloom/library/utils/payment';
import { getProjectDuration } from '@bloom/library/utils/project';
import { escapeHTML, formatMoney } from '@bloom/library/utils/string';
import { formatDuration } from '@bloom/library/utils/time';

import { useQuoteRequest } from '../../quote-request-context';

interface IProps {
  'data-testid': string;
  documentObj?: Document | null;
  isSelected: boolean;
  pkg: {
    coverImage: string;
    displayName: string;
    id: string;
    package: Omit<IPackage, 'discount'> & { discount: DiscountItemResponse };
  };
}

const PackageDiscount: React.FC<{ discount: DiscountItemResponse }> = (props) => {
  const { discount } = props;

  const [
    {
      questionnaire: { owner },
    },
  ] = useQuoteRequest();

  const currencyCode = owner.currencyCode || 'USD';

  let discountText;

  if (discount.type === 'MONEY') {
    discountText = formatMoney(+discount.amount, currencyCode);
  } else {
    discountText = `${discount.amount}%`;
  }
  discountText += ' off applied!';

  return (
    <Text className="text-right" data-testid="package-discount">
      {discountText}
    </Text>
  );
};

const Package: React.FC<IProps> = (props) => {
  const { 'data-testid': dataTestId, documentObj, isSelected, pkg } = props;
  const { coverImage, displayName } = pkg;

  const [
    {
      questionnaire: { owner },
    },
    { selectInstantBookingPackage },
  ] = useQuoteRequest();

  const currencyCode = owner.currencyCode || 'USD';
  const defaultTaxPercent = Number(owner.defaultTaxPercent) || 0;

  const { src } = useImageFallback(coverImage || '', 'SM');

  const { taxAmount, total } = getInstantBookingPackagePaymentDetails(
    pkg.package,
    [],
    defaultTaxPercent
  );
  const duration = formatDuration(getProjectDuration(pkg));

  function handlePackageSelect(name: string, packageId: string) {
    selectInstantBookingPackage(packageId);
  }

  return (
    <SelectBox
      className="flex-1 !p-6"
      data-testid={dataTestId}
      name="package"
      onClick={handlePackageSelect}
      selected={isSelected}
      style={{ maxWidth: 380, minWidth: 280 }}
      value={pkg.id}
    >
      <div className="flex h-full flex-col gap-6 text-black dark:text-white">
        <header className="flex items-start gap-3">
          <Checkbox
            checked={isSelected}
            data-testid="package-checkbox"
            label={
              <H2
                dangerouslySetInnerHTML={{ __html: escapeHTML(displayName) }}
                data-testid="package-title"
                title={displayName}
              />
            }
            onChange={doNothing}
            type="radio"
          />

          <Text className="ml-auto" data-testid="package-duration" size={TextSizeEnum.SM}>
            {duration}
          </Text>
        </header>

        <div className="flex flex-col gap-6">
          {coverImage ? (
            <img
              className="bg-black-15 dark:bg-white-15 aspect-video w-full rounded-sm object-cover object-center"
              data-testid="package-cover-image"
              src={src}
            />
          ) : null}

          <ul className="divide-black-15 dark:divide-white-15 flex flex-col divide-y">
            {pkg.package.items.map((item, index) => (
              <Tooltip
                content={item.description}
                contentClassName="whitespace-pre-wrap"
                enabled={!!item.description}
                key={item.id || index}
                offsetProps={{ alignmentAxis: 10, mainAxis: 0 }}
                placement="bottom-end"
                root={documentObj?.body}
              >
                <li className="flex items-center justify-between py-3">
                  <div className="inline-flex items-center gap-2">
                    <CheckIcon width={16} />

                    <Text
                      as="span"
                      className="mr-auto"
                      dangerouslySetInnerHTML={{ __html: escapeHTML(item.displayName) }}
                    />
                  </div>

                  <Text as="span" className="text-black-50 dark:text-white-50">
                    x{item.quantity}
                  </Text>
                </li>
              </Tooltip>
            ))}
          </ul>
        </div>

        <footer className="mt-auto flex items-center justify-between pt-12">
          <H1 as="span" data-testid="package-cost">
            {formatMoney(Math.max(0, total - taxAmount), currencyCode)}
          </H1>

          {Number(pkg.package.discount?.amount) ? (
            <PackageDiscount discount={pkg.package.discount} />
          ) : null}
        </footer>
      </div>
    </SelectBox>
  );
};

export { Package };
