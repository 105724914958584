import React, { useMemo } from 'react';

import { useLocaleSettings } from '@bloom/ui/hooks/useLocaleSettings';

import { useImageFallback } from '@bloom/library/components/hooks/useImageFallback';
import { useMoment } from '@bloom/library/components/hooks/useMoment';
import { usePublicAccount } from '@bloom/library/components/hooks/usePublicAccount';
import {
  useCurrentQuestion,
  useQuoteRequest,
  useSelectedPackage,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { H2 } from '@bloom/library/components/Typography/H2';
import { IBookingPackageQuestion } from '@bloom/library/types/questionnaire';
import { convertTimeToObject } from '@bloom/library/utils/date';
import { getInstantBookingPackagePaymentDetails } from '@bloom/library/utils/payment';
import { formatMoney } from '@bloom/library/utils/string';

const PackageOverview: React.FC = () => {
  const [
    {
      dateTimePicker,
      instantBooking,
      questionnaire: { accountId, owner },
    },
  ] = useQuoteRequest();

  const { account } = usePublicAccount(accountId);

  const { addons } = instantBooking;
  const { selectedDate, selectedTime, selectedTimezoneName } = dateTimePicker;

  const selectedPackage = useSelectedPackage();

  const { momentTz } = useMoment();
  const packageItem = selectedPackage?.package;

  const { localeSettings } = useLocaleSettings();

  const { currencyCode } = owner;
  const defaultTaxPercent = Number(owner.defaultTaxPercent) || 0;

  const { src } = useImageFallback(selectedPackage?.coverImage || '', 'SM');

  const question = useCurrentQuestion() as IBookingPackageQuestion;
  const { allDay = false, schedulingEnabled = true } = question.options;

  const timezone = useMemo(() => ({ name: selectedTimezoneName }), [selectedTimezoneName]);

  const eventDate = useMemo(() => {
    if (allDay && selectedDate) {
      return momentTz(selectedDate.slice(0, 10), timezone).utc().format();
    }

    if (selectedDate && selectedTime) {
      return momentTz(selectedDate.slice(0, 10), timezone)
        .set(convertTimeToObject(selectedTime))
        .utc()
        .format();
    }

    return '';
  }, [allDay, momentTz, selectedDate, selectedTime, timezone]);

  const eventTime = useMemo(() => {
    if (schedulingEnabled) {
      if (allDay) {
        return 'All day';
      }

      return `${momentTz(eventDate, timezone).format(
        localeSettings.timeFormat
      )} (${momentTz(eventDate, timezone).zoneAbbr()})`;
    }

    return `&dash`;
  }, [allDay, eventDate, localeSettings.timeFormat, momentTz, schedulingEnabled, timezone]);

  if (!packageItem) {
    return null;
  }

  const {
    addonsCost,
    discountAmount,
    packageCost,
    subtotal,
    taxableAmount,
    taxableInTotalAmount,
    taxAmount,
    taxIncludedInTotal,
    total,
    totalExcludingTax,
  } = getInstantBookingPackagePaymentDetails(packageItem, addons, defaultTaxPercent);

  return (
    <>
      <H2>Summary</H2>

      {selectedPackage?.coverImage ? (
        <img
          className="bg-black-15 dark:bg-white-15 mt-6 aspect-video w-full rounded-sm object-cover object-center"
          src={src}
        />
      ) : null}

      <div className="divide-black-50 dark:divide-white-50 mt-3 flex flex-col gap-2 divide-y">
        {schedulingEnabled && eventDate ? (
          <table className="w-full">
            <tbody>
              <tr>
                <th className="text-black-50 dark:text-white-50 pt-3 pb-2 text-left font-normal">
                  Date
                </th>
                <td
                  className="pt-3 pb-2 text-right font-medium"
                  dangerouslySetInnerHTML={{
                    __html: schedulingEnabled
                      ? momentTz(eventDate, timezone).format('ll')
                      : `&dash;`,
                  }}
                />
              </tr>

              {allDay ? null : (
                <tr>
                  <th className="text-black-50 dark:text-white-50 py-2 text-left font-normal">
                    Time
                  </th>
                  <td
                    className="py-2 text-right font-medium"
                    dangerouslySetInnerHTML={{ __html: eventTime }}
                  />
                </tr>
              )}
            </tbody>
          </table>
        ) : null}

        <table className="w-full">
          <tbody>
            <tr>
              <th className="text-black-50 dark:text-white-50 pt-3 pb-2 text-left font-normal">
                Package
              </th>
              <td className="pt-3 pb-2 text-right font-medium">
                {formatMoney(packageCost, currencyCode)}
              </td>
            </tr>

            <tr>
              <th className="text-black-50 dark:text-white-50 py-2 text-left font-normal">
                Add-ons
              </th>
              <td className="py-2 text-right font-medium">
                {formatMoney(addonsCost, currencyCode)}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="w-full">
          <tbody>
            <tr>
              <th className="text-black-50 dark:text-white-50 pt-3 pb-2 text-left font-normal">
                Subtotal
              </th>
              <td className="pt-3 pb-2 text-right font-medium">
                {formatMoney(subtotal, currencyCode)}
              </td>
            </tr>

            {discountAmount ? (
              <tr>
                <th className="text-black-50 dark:text-white-50 py-2 text-left font-normal">
                  Discount
                </th>
                <td className="py-2 text-right font-medium">
                  -{formatMoney(discountAmount, currencyCode)}
                </td>
              </tr>
            ) : null}

            {totalExcludingTax ? (
              <tr>
                <th className="text-black-50 dark:text-white-50 py-2 text-left font-normal">
                  Total excluding tax
                </th>
                <td className="py-2 text-right font-medium">
                  {formatMoney(totalExcludingTax, currencyCode)}
                </td>
              </tr>
            ) : null}

            {taxIncludedInTotal && taxableInTotalAmount ? (
              <tr>
                <th className="text-black-50 dark:text-white-50 py-2 text-left font-normal">
                  {account?.defaultTaxName || 'Tax'} ({defaultTaxPercent}% incl. on{' '}
                  {formatMoney(taxableInTotalAmount, currencyCode)})
                </th>
                <td className="py-2 text-right font-medium">
                  {formatMoney(taxIncludedInTotal, currencyCode)}
                </td>
              </tr>
            ) : null}

            {taxAmount ? (
              <tr>
                <th className="text-black-50 dark:text-white-50 py-2 text-left font-normal">
                  {account?.defaultTaxName || 'Tax'} ({defaultTaxPercent}%
                  {taxableAmount ? ` on ${formatMoney(taxableAmount, currencyCode)}` : ''})
                </th>
                <td className="py-2 text-right font-medium">
                  {formatMoney(taxAmount, currencyCode)}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>

        <table className="w-full">
          <tbody>
            <tr>
              <th className="text-black-50 dark:text-white-50 pt-3 pb-2 text-left font-normal">
                Total
              </th>
              <H2 as="td" className="pt-3 pb-2 text-right font-medium">
                {formatMoney(total, currencyCode)}
              </H2>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export { PackageOverview };
