export function getResponseErrorMessage(responseBody) {
  const { message: formMessage, clientMessages = [] } = responseBody;
  if (clientMessages.length === 0) {
    return formMessage || 'Something went wrong. Please try again later.';
  }
  if (clientMessages.length === 1) {
    const { messages = [] } = clientMessages[0] || {};
    // TODO it looks like messages is always an array, find out if it's true.
    if (Array.isArray(messages)) {
      return messages[0];
    }
    return messages || formMessage;
  }
  return 'Your input contains two or more invalid entries.';
}
