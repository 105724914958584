import React, { useId, useMemo, useRef, useState } from 'react';

import { FormikProps } from 'formik';

import { Checkbox } from '@bloom/ui/components/Checkbox';
import { SecurePaymentBadge } from '@bloom/ui/components/SecurePaymentBadge';
import { NoteText } from '@bloom/ui/components/Typography/NoteText';
import { doNothing } from '@bloom/ui/utils/empty-value';

import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { FormState } from '@bloom/library/components/Form/FormState';
import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useMoment } from '@bloom/library/components/hooks/useMoment';
import { usePublicAccount } from '@bloom/library/components/hooks/usePublicAccount';
import { MasterCardLogo } from '@bloom/library/components/Icon/MasterCard';
import { PlaidLogo } from '@bloom/library/components/Icon/Plaid';
import { PlusIcon } from '@bloom/library/components/Icon/Plus';
import { StripeLogo } from '@bloom/library/components/Icon/Stripe';
import { VisaLogo } from '@bloom/library/components/Icon/Visa';
import { CreditCardPaymentOption } from '@bloom/library/components/PaymentForm/CreditCardPaymentOption';
import { PaymentScheduleOption } from '@bloom/library/components/PaymentForm/PaymentScheduleOption';
import { StripeAchForm } from '@bloom/library/components/PaymentForm/StripeAchForm';
import { StripeVerificationModal } from '@bloom/library/components/PaymentForm/StripeVerificationModal';
import { QuoteRequestScreenEnum } from '@bloom/library/components/QuoteRequest/actions';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import {
  useCurrentQuestion,
  useQuestionnaireSubmit,
  useQuestions,
  useQuoteRequest,
  useSelectedPackage,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';
import { PackageOverview } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion/PackageOverview';
import { H2 } from '@bloom/library/components/Typography/H2';
import { getResponseErrorMessage } from '@bloom/library/components/Validator/utils';
import { ClientInfoFieldEnum } from '@bloom/library/types/templates';
import { getCookie } from '@bloom/library/utils/browser';
import { convertTimeToObject } from '@bloom/library/utils/date';
import { getBusinessNameFromAccount } from '@bloom/library/utils/misc';
import { getInstantBookingPackagePaymentDetails } from '@bloom/library/utils/payment';
import { escapeHTML, formatMoney } from '@bloom/library/utils/string';

interface IProps {
  documentObj?: Document;
  onBackClick: () => void;
  onClose: () => void;
  windowObj?: Window;
}

const PaymentForm = React.forwardRef<
  { submit: (e?: React.FormEvent<HTMLFormElement>) => void },
  {
    amount: number;
    documentObj?: Document;
    isPreview: boolean;
    onFinalNextClick: () => void;
    onLoadingSet: (isLoading: boolean) => void;
    onSetVerificationDetails: (
      verificationDetails: Record<'clientSecret' | 'redirectUrl', string>
    ) => void;
    paymentOption: 'ach' | 'card';
    setPaymentOption: (paymentOptions: 'ach' | 'card') => void;
    windowObj: Window;
    withAchPayment: boolean;
  } & FormikProps<IFormValues>
>((props, forwardedRef) => {
  const {
    amount,
    documentObj = document,
    isPreview,
    onFinalNextClick,
    onLoadingSet,
    onSetVerificationDetails,
    paymentOption,
    setPaymentOption,
    windowObj = window,
    withAchPayment,
    ...formikProps
  } = props;

  const { values } = formikProps;

  const { momentTz } = useMoment();

  const { showErrorMessage } = useInfoMessage();
  const [{ dateTimePicker, instantBooking, modal, questionnaire }, { sendAnswer }] =
    useQuoteRequest();
  const { selectedDate, selectedTime, selectedTimezoneName } = dateTimePicker;
  const { addons, clientInfo, contracts, selectedPackageId } = instantBooking;
  const { accountId, owner, settings } = questionnaire;

  const { account } = usePublicAccount(accountId);
  const buttonColor = useCustomColor(settings?.button?.color || account?.settings?.customColor);

  const {
    currencyCode,
    defaultTaxPercent,
    primaryPaymentMethodData,
    primaryPaymentMethodProvider,
  } = owner;

  const selectedPackage = useSelectedPackage();
  const supportsCreditCard = !selectedPackage?.bookWithoutPayment;

  const creditCardHtmlId = useId();
  const achHtmlId = useId();

  const question = useCurrentQuestion();

  const { allDay } = question.options;

  function getEventDate() {
    if (allDay && selectedDate) {
      return selectedDate.slice(0, 10);
    }

    if (selectedDate && selectedTime) {
      const timezone = { name: selectedTimezoneName };

      return momentTz(selectedDate.slice(0, 10), timezone)
        .set(convertTimeToObject(selectedTime))
        .utc()
        .format();
    }

    return '';
  }

  function handleSubmit(token: string, verificationToken?: string) {
    let totalPayments = 1;

    const { minimumDue, total } = getInstantBookingPackagePaymentDetails(
      selectedPackage.package,
      addons,
      defaultTaxPercent
    );
    if (minimumDue !== total && values.dueAmount && values.dueAmount === minimumDue) {
      totalPayments = 2;
    }

    const buyerId = getCookie('buyerId');

    const payload = {
      addons: addons.map((addon) => ({ quantity: addon.quantity, slug: addon.slug })),
      bookingPackageId: selectedPackageId,
      buyerId,
      clientInfo,
      contracts,
      eventDate: getEventDate(),
      scaRedirectUrl: /^stripe/i.test(owner.primaryPaymentMethodProvider || '')
        ? `${process.env.BLOOM_DEMO_APP_URL}/3ds-authentication`
        : undefined,
      token,
      totalPayments: totalPayments,
      verificationToken,
    };

    onLoadingSet(true);

    return sendAnswer(question.questionnaireId, {
      answerGroupId: modal.answerGroupId,
      payload,
      questionId: question.id,
    })
      .then((action) => {
        if (action.status === AsyncStatusEnum.SUCCESS) {
          if (action.data.answer.payload.scaRequired) {
            onSetVerificationDetails(action.data.answer.payload.verificationDetails);
            return action;
          }

          onFinalNextClick();
        }

        if (action.status === AsyncStatusEnum.ERROR) {
          const errorMessage = getResponseErrorMessage(action.error);
          showErrorMessage(errorMessage);
          // This error will be caught inside StripePayment, or SquarePayment
          // component and shown to the user, the loading state will be turned off.
          throw new Error(errorMessage);
        }

        return action;
      })
      .finally(() => {
        onLoadingSet(false);
      });
  }

  if (/^stripe|square/i.test(primaryPaymentMethodProvider)) {
    return (
      <>
        {withAchPayment ? (
          <div data-testid="ach-form">
            <div
              className="flex cursor-pointer items-center justify-between gap-4 py-6"
              onClick={() => setPaymentOption('ach')}
            >
              <Checkbox
                checked={paymentOption === 'ach'}
                className="items-center"
                data-testid="ach-radio"
                id={achHtmlId}
                label={
                  <H2 as="label" className="mt-0.5 cursor-pointer" htmlFor={achHtmlId}>
                    Bank Transfer with ACH
                  </H2>
                }
                onChange={doNothing}
                type="radio"
                value={'ach'}
              />
              <div className="text-grey flex items-center justify-end gap-2">
                <PlaidLogo />
                <StripeLogo />
              </div>
            </div>

            {paymentOption === 'ach' ? (
              <div className="pb-6">
                <NoteText>
                  Bank transfers, also known as ACH payments, can take up to five business days to
                  complete. Use the Plaid authentication to schedule the transfer right away.
                </NoteText>

                <StripeAchForm
                  amount={amount}
                  buttonRadius={settings?.button?.borderRadius}
                  buyerFirstName={clientInfo[ClientInfoFieldEnum.FIRST_NAME] || ''}
                  buyerLastName={clientInfo[ClientInfoFieldEnum.LAST_NAME] || ''}
                  currencyCode={currencyCode}
                  customColor={buttonColor}
                  disableSubmitButton={isPreview}
                  isContractsSigned
                  onCharge={handleSubmit}
                  onChargeSuccess={doNothing}
                  ref={forwardedRef}
                />

                <SecurePaymentBadge className="mt-6 flex" />
              </div>
            ) : null}
          </div>
        ) : null}

        {primaryPaymentMethodProvider && supportsCreditCard ? (
          <div>
            <div
              className="flex cursor-pointer items-center justify-between gap-4 py-6"
              onClick={() => setPaymentOption('card')}
            >
              <Checkbox
                checked={paymentOption === 'card'}
                className="items-center"
                data-testid="credit-card-radio"
                id={creditCardHtmlId}
                label={
                  <H2 as="label" className="mt-0.5 cursor-pointer" htmlFor={creditCardHtmlId}>
                    Credit Card
                  </H2>
                }
                onChange={doNothing}
                type="radio"
                value="card"
              />

              <div className="text-grey flex items-center justify-end gap-2">
                <MasterCardLogo />
                <VisaLogo />
                <PlusIcon />
              </div>
            </div>
            {paymentOption === 'card' ? (
              <div className="pb-6">
                <CreditCardPaymentOption
                  additionalFee={0}
                  amount={amount}
                  buttonRadius={settings?.button?.borderRadius}
                  buyerFirstName={clientInfo[ClientInfoFieldEnum.FIRST_NAME] ?? ''}
                  buyerLastName={clientInfo[ClientInfoFieldEnum.LAST_NAME] ?? ''}
                  currencyCode={currencyCode}
                  customColor={buttonColor}
                  disableSubmitButton={isPreview}
                  documentObj={documentObj}
                  isContractsSigned
                  onCharge={handleSubmit}
                  onChargeSuccess={doNothing}
                  payoutMethodData={primaryPaymentMethodData}
                  payoutMethodProvider={primaryPaymentMethodProvider}
                  theme={settings?.colorTheme}
                  windowObj={windowObj}
                />
                <SecurePaymentBadge className="mt-6 flex" />
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    );
  }

  return (
    <div className="flex flex-1 flex-col items-start justify-center py-12">
      <p>
        Oops,{' '}
        <span
          dangerouslySetInnerHTML={{
            __html: escapeHTML(getBusinessNameFromAccount(account)),
          }}
        />{' '}
        has&apos;t setup a payment method.
      </p>
      <p>
        This package requires a payment, but there is no method set up for us to process the
        payment.
      </p>
    </div>
  );
});

interface IFormValues {
  dueAmount: number;
}

const PaymentSchedulePicker: React.FC<FormikProps<IFormValues>> = (props) => {
  const { setFieldValue, values } = props;
  const { moment, momentTz } = useMoment();

  const [{ dateTimePicker, instantBooking, questionnaire }] = useQuoteRequest();
  const { owner } = questionnaire;
  const { currencyCode, defaultTaxPercent } = owner;

  const selectedPackage = useSelectedPackage();

  const { selectedDate, selectedTime, selectedTimezoneName } = dateTimePicker;
  const { addons } = instantBooking;

  const paymentRetainer = selectedPackage?.package.paymentRetainer || {};
  const { minimumDue, total } = getInstantBookingPackagePaymentDetails(
    selectedPackage?.package,
    addons,
    defaultTaxPercent
  );

  const question = useCurrentQuestion();
  const { allDay } = question.options;

  const isRetainerEnabled = total !== minimumDue;

  /*
   * Important! this func must always return valid moment date.
   */
  function getFinalPaymentDate(paymentDueOffset: string) {
    const timezone = { name: selectedTimezoneName };

    // A freelancer can have an instant booking form without date step.
    // In that case, we should use current date, otherwise the ui will show "Invalid date".
    const date = selectedDate ? momentTz(selectedDate.slice(0, 10), timezone) : moment();

    date.set(convertTimeToObject(allDay ? '00:00' : selectedTime));

    // Convert date from local time to UTC before saving.
    const eventDate = date.utc().format();

    if (!eventDate || !paymentDueOffset) {
      return moment();
    }

    let dayOffset = 0;
    switch (paymentDueOffset) {
      case 'ON_DAY_OF_EVENT':
        dayOffset = 0;
        break;
      case 'ONE_DAY_BEFORE':
        dayOffset = -1;
        break;
      case 'THREE_DAYS_BEFORE':
        dayOffset = -3;
        break;
      case 'SEVEN_DAYS_BEFORE':
        dayOffset = -7;
        break;
      case 'FOURTEEN_DAYS_BEFORE':
        dayOffset = -14;
        break;
      case 'THIRTY_DAYS_BEFORE':
        dayOffset = -30;
        break;
      case 'SIXTY_DAYS_BEFORE':
        dayOffset = -60;
        break;
      case 'ONE_DAY_AFTER':
        dayOffset = 1;
        break;
      case 'THREE_DAYS_AFTER':
        dayOffset = 3;
        break;
      case 'SEVEN_DAYS_AFTER':
        dayOffset = 7;
        break;
      case 'FOURTEEN_DAYS_AFTER':
        dayOffset = 14;
        break;
      case 'THIRTY_DAYS_AFTER':
        dayOffset = 30;
        break;
      case 'SIXTY_DAYS_AFTER':
        dayOffset = 60;
        break;
      default:
        break;
    }

    return momentTz(eventDate, timezone).add(dayOffset, 'days');
  }

  const finalRetainerDate = paymentRetainer.paymentDue;
  const finalRetainerDateFormatted = getFinalPaymentDate(finalRetainerDate);

  const retainerAmount = formatMoney(minimumDue, currencyCode);
  const afterRetainerAmount = formatMoney(total - minimumDue, currencyCode);
  let retainerDescription = `Pay ${retainerAmount} now `;

  const retainerChargeText = paymentRetainer.isAutoCharge
    ? ` automatically charged to the same card on ${finalRetainerDateFormatted.format('ll')}.`
    : ` due on ${finalRetainerDateFormatted.format('ll')}.`;

  retainerDescription += finalRetainerDate
    ? `and the remaining balance of ${afterRetainerAmount} will be ${retainerChargeText}`
    : 'to get the project booked.';

  if (isRetainerEnabled) {
    return (
      <div>
        <H2>Payment Schedule</H2>

        <div className="mt-6 grid gap-6 sm:grid-cols-2">
          <PaymentScheduleOption
            currencyCode={currencyCode}
            data-testid="full-payment-checkbox"
            isSelected={values.dueAmount === total || values.dueAmount === undefined}
            label="Full Payment"
            name="dueAmount"
            onChange={setFieldValue}
            value={total}
          />

          <PaymentScheduleOption
            currencyCode={currencyCode}
            data-testid="retainer-payment-checkbox"
            isSelected={values.dueAmount === minimumDue}
            label="Partial Payment"
            name="dueAmount"
            onChange={setFieldValue}
            value={minimumDue}
          />
        </div>

        <div className="text-grey my-6 text-xs">
          {values.dueAmount === minimumDue
            ? retainerDescription
            : 'Pay in full and not worry about any future payments.'}
        </div>
      </div>
    );
  }

  return null;
};

const Payment: React.FC<IProps> = (props) => {
  const { documentObj = document, onBackClick, onClose, windowObj = window } = props;

  const [verificationDetails, setVerificationDetails] = useState<Record<
    'clientSecret' | 'redirectUrl',
    string
  > | null>(null);

  const submitRef = useRef<{ submit: (e?: React.FormEvent<HTMLFormElement>) => void }>(null);

  const [isLoading, setLoading] = useState(false);

  const { showErrorMessage } = useInfoMessage();

  const [
    { instantBooking, isPreview, modal, questionnaire },
    { resetAnswerGroupId, showNextQuestion, showView },
  ] = useQuoteRequest();
  const { owner } = questionnaire;

  const { submitQuestionnaireForm } = useQuestionnaireSubmit();

  const { addons } = instantBooking;
  const selectedPackage = useSelectedPackage();

  const { questionIndex } = modal;
  const questions = useQuestions();

  const isLast = questionIndex === questions.length - 1;

  const { defaultTaxPercent, primaryPaymentMethodData, primaryPaymentMethodProvider } = owner;

  const [paymentOption, setPaymentOption] = useState<'ach' | 'card'>(
    selectedPackage?.achPayment ? 'ach' : 'card'
  );

  const initialValues: IFormValues = useMemo(() => {
    const { total } = getInstantBookingPackagePaymentDetails(
      selectedPackage?.package,
      addons,
      defaultTaxPercent
    );

    return { dueAmount: total };
  }, [defaultTaxPercent, addons, selectedPackage?.package]);

  function closeStripeAuthenticationModal() {
    setVerificationDetails(null);
  }

  function handleFinalNextClick() {
    if (!isLast) {
      showNextQuestion();
    } else if (questionnaire.settings.isCustomConfirmationEnabled) {
      submitQuestionnaireForm();
    } else if (questionnaire.outroText) {
      showView(QuoteRequestScreenEnum.OUTRO);
    } else {
      resetAnswerGroupId();
      onClose();
    }
  }

  const withAchPayment =
    /^stripe/i.test(primaryPaymentMethodProvider) && !!selectedPackage?.achPayment;

  return (
    <>
      <div
        className="font-regular container py-12 md:py-20"
        data-testid="question-instant-booking-payment"
      >
        <div>
          <QuoteRequestHeadline
            headerProps={{
              dangerouslySetInnerHTML: { __html: 'Please enter your payment details' },
            }}
            subheaderProps={{
              dangerouslySetInnerHTML: {
                __html: 'Select the available payment schedule and payment options. ',
              },
            }}
          />

          <div className="mt-12 grid w-full gap-6 sm:grid-cols-[2fr_1fr] sm:gap-12">
            <FormState<IFormValues> initialValues={initialValues} onSubmit={doNothing}>
              {(formikProps) => {
                const { values } = formikProps;
                return (
                  <div className="divide-regular relative flex w-full flex-1 flex-col divide-y">
                    {(withAchPayment && paymentOption === 'ach') ||
                    /^stripe|square/i.test(primaryPaymentMethodProvider) ? (
                      <PaymentSchedulePicker {...formikProps} />
                    ) : null}

                    <PaymentForm
                      amount={Number(values.dueAmount)}
                      documentObj={documentObj}
                      isPreview={isPreview}
                      onFinalNextClick={handleFinalNextClick}
                      onLoadingSet={setLoading}
                      onSetVerificationDetails={setVerificationDetails}
                      paymentOption={paymentOption}
                      ref={submitRef}
                      setPaymentOption={setPaymentOption}
                      windowObj={windowObj}
                      withAchPayment={withAchPayment}
                      {...formikProps}
                    />
                  </div>
                );
              }}
            </FormState>

            <StripeVerificationModal
              clientSecret={verificationDetails?.clientSecret || ''}
              onClose={closeStripeAuthenticationModal}
              onVerificationError={showErrorMessage}
              onVerificationSuccess={handleFinalNextClick}
              redirectUrl={verificationDetails?.redirectUrl}
              stripeAccountId={primaryPaymentMethodData?.accountId}
            />

            <div>
              <PackageOverview />
            </div>
          </div>

          <ButtonsContainer>
            <QuoteRequestPrimaryButton
              data-testid="back-button"
              disabled={isLoading}
              onClick={onBackClick}
              variant="secondary"
            >
              Back
            </QuoteRequestPrimaryButton>
          </ButtonsContainer>
        </div>
      </div>
    </>
  );
};

export { Payment };
