import React from 'react';

import { twMerge } from 'tailwind-merge';

import { QuestionnaireAlignment } from '@bloom/codegen/models/QuestionnaireAlignment';

import { ArrowIcon } from '@bloom/library/components/Icon/Arrow';
import { QuoteRequestHeadline } from '@bloom/library/components/QuoteRequest/components/QuoteRequestHeadline';
import { QuoteRequestPrimaryButton } from '@bloom/library/components/QuoteRequest/components/QuoteRequestPrimaryButton';
import {
  useCurrentQuestion,
  useQuoteRequest,
  useSelectedPackage,
} from '@bloom/library/components/QuoteRequest/quote-request-context';
import { ButtonsContainer } from '@bloom/library/components/QuoteRequest/views/common/ButtonsContainer';
import { Addon } from '@bloom/library/components/QuoteRequest/views/InstantBookingQuestion/Addon';
import { IBookingPackageQuestion } from '@bloom/library/types/questionnaire';
import { escapeHTML } from '@bloom/library/utils/string';

interface IProps {
  onBackClick: VoidFunction;
  onNextClick: VoidFunction;
  windowObj?: Window;
}

const AddonsList: React.FC<IProps> = (props) => {
  const { onBackClick, onNextClick, windowObj } = props;

  const selectedPackage = useSelectedPackage();

  const question = useCurrentQuestion() as IBookingPackageQuestion;

  const [{ questionnaire }] = useQuoteRequest();
  const { settings } = questionnaire;
  const alignment = settings?.alignment || QuestionnaireAlignment.CENTER;

  return (
    <div className="font-regular container py-12 md:py-20" data-testid="instant-booking-addons">
      <QuoteRequestHeadline
        descriptionProps={{
          dangerouslySetInnerHTML: { __html: escapeHTML(question.options.addonsDescription || '') },
        }}
        headerProps={{
          dangerouslySetInnerHTML: {
            __html: escapeHTML(question.options.addonsTitle || 'Add-Ons'),
          },
        }}
      />

      <div
        className={twMerge(
          'flex w-full flex-wrap gap-6',
          alignment === QuestionnaireAlignment.CENTER ? 'justify-center' : '',
          alignment === QuestionnaireAlignment.RIGHT ? 'justify-end' : '',
          alignment === QuestionnaireAlignment.LEFT ? 'justify-start' : ''
        )}
      >
        {selectedPackage?.package.addons.map((addon, index) => (
          <Addon addon={addon} data-testid="addon-item" key={index} />
        ))}
      </div>

      <ButtonsContainer onNextClick={onNextClick} windowObj={windowObj}>
        <QuoteRequestPrimaryButton
          data-testid="back-button"
          onClick={onBackClick}
          variant="secondary"
        >
          Back
        </QuoteRequestPrimaryButton>

        <QuoteRequestPrimaryButton data-testid="next-button" onClick={onNextClick}>
          Next <ArrowIcon />
        </QuoteRequestPrimaryButton>
      </ButtonsContainer>
    </div>
  );
};

export { AddonsList };
